import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  Time: string;
};

export type AdminReportPublicationManagement = {
  __typename?: "AdminReportPublicationManagement";
  ordersNeedingResultUrl: OrderConnection;
};

export type AdminReportPublicationManagementOrdersNeedingResultUrlArgs = {
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
  treatReportingSmartsResultUrlAsNull?: InputMaybe<Scalars["Boolean"]>;
};

export type AdminReports = {
  __typename?: "AdminReports";
  publicationManagement: AdminReportPublicationManagement;
};

export type AmTask = {
  __typename?: "AmTask";
  completedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  info?: Maybe<Scalars["String"]>;
  taskType: Scalars["String"];
};

export type AmTaskConnection = {
  __typename?: "AmTaskConnection";
  nodes: Array<AmTask>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ApprovePaymentBatchFundingInput = {
  paymentBatchId: Scalars["Int"];
};

export type ApprovePaymentBatchFundingPaypalInput = {
  paymentBatchId: Scalars["Int"];
};

export type ApprovePaymentBatchFundingPaypalResponse = {
  __typename?: "ApprovePaymentBatchFundingPaypalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ApprovePaymentBatchFundingResponse = {
  __typename?: "ApprovePaymentBatchFundingResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ApproveUserWritingSampleInput = {
  userWritingSampleId: Scalars["Int"];
};

export type ApproveUserWritingSampleResponse = {
  __typename?: "ApproveUserWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type BlockDomainConnection = {
  __typename?: "BlockDomainConnection";
  nodes: Array<BlockDomainCustomer>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type BlockDomainCustomer = {
  __typename?: "BlockDomainCustomer";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  domain: Scalars["String"];
  id: Scalars["Int"];
};

export enum CampaignCategoriesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type CampaignCategory = {
  __typename?: "CampaignCategory";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CampaignCategoryConnection = {
  __typename?: "CampaignCategoryConnection";
  nodes: Array<CampaignCategory>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export enum CategoriesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type Category = {
  __typename?: "Category";
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
};

export type CategoryConnection = {
  __typename?: "CategoryConnection";
  nodes: Array<Category>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ChangePasswordInput = {
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type ChangePasswordResponse = {
  __typename?: "ChangePasswordResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Checklist = {
  __typename?: "Checklist";
  checklistItems: Array<ChecklistItem>;
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ChecklistDefinition = {
  __typename?: "ChecklistDefinition";
  checklistDefinitionItems: Array<ChecklistDefinitionItem>;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ChecklistDefinitionConnection = {
  __typename?: "ChecklistDefinitionConnection";
  nodes: Array<ChecklistDefinition>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ChecklistDefinitionItem = {
  __typename?: "ChecklistDefinitionItem";
  checklistDefinitionId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type ChecklistItem = {
  __typename?: "ChecklistItem";
  completedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type CheckoutInput = {
  customerId?: InputMaybe<Scalars["Int"]>;
  orderIds?: InputMaybe<Array<Scalars["Int"]>>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  partnerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  payNow?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutResponse = {
  __typename?: "CheckoutResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ClaimTaskInput = {
  taskId: Scalars["Int"];
};

export type ClaimTaskResponse = {
  __typename?: "ClaimTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  task?: Maybe<Task>;
};

export type ClearProposedCustomerKeywordsRejectionInput = {
  customerKeywordProposalId: Scalars["Int"];
};

export type ClearProposedCustomerKeywordsRejectionResponse = {
  __typename?: "ClearProposedCustomerKeywordsRejectionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ConfirmEmailInput = {
  confirmationToken: Scalars["String"];
};

export type ConfirmEmailResponse = {
  __typename?: "ConfirmEmailResponse";
  ok: Scalars["Boolean"];
};

export type ContentIdeation = {
  __typename?: "ContentIdeation";
  id: Scalars["Int"];
  idea: Scalars["String"];
};

export type ContentItem = {
  __typename?: "ContentItem";
  classification?: Maybe<Scalars["Int"]>;
  content: Scalars["String"];
  feedbackContentItems: Array<FeedbackContentItem>;
  id: Scalars["Int"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  probability?: Maybe<Scalars["Float"]>;
  thumbnailFile?: Maybe<File>;
  title: Scalars["String"];
};

export type ContentItemDelivery = {
  __typename?: "ContentItemDelivery";
  content: Scalars["String"];
  keyword: Scalars["String"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  partnerLogoUrl?: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  publicUrl: Scalars["String"];
  title: Scalars["String"];
};

export type Country = {
  __typename?: "Country";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CreateAmTaskInput = {
  completedAt: Scalars["Date"];
  customerId: Scalars["Int"];
  info?: InputMaybe<Scalars["String"]>;
  taskType: Scalars["String"];
};

export type CreateAmTaskResponse = {
  __typename?: "CreateAmTaskResponse";
  amTask?: Maybe<AmTask>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateBlockDomainCustomersInput = {
  customerId: Scalars["Int"];
  domains: Array<Scalars["String"]>;
};

export type CreateBlockDomainCustomersResponse = {
  __typename?: "CreateBlockDomainCustomersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateBulkProposedCustomerKeywordsInput = {
  customerKeywordProposalId: Scalars["Int"];
  keywords: Array<Scalars["String"]>;
};

export type CreateBulkProposedCustomerKeywordsResponse = {
  __typename?: "CreateBulkProposedCustomerKeywordsResponse";
  proposedCustomerKeywords: Array<ProposedCustomerKeyword>;
};

export type CreateBusinessSubscriptionOrdersInput = {
  customerId: Scalars["Int"];
};

export type CreateBusinessSubscriptionOrdersResponse = {
  __typename?: "CreateBusinessSubscriptionOrdersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type CreateCategoryInput = {
  name: Scalars["String"];
};

export type CreateCategoryResponse = {
  __typename?: "CreateCategoryResponse";
  category?: Maybe<Category>;
};

export type CreateChecklistDefinitionInput = {
  items: Array<CreateChecklistDefinitionItemInput>;
  name: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreateChecklistDefinitionItemInput = {
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type CreateChecklistDefinitionResponse = {
  __typename?: "CreateChecklistDefinitionResponse";
  checklistDefinition?: Maybe<ChecklistDefinition>;
};

export type CreateCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDisplay?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  uberallCategoryId?: InputMaybe<Scalars["Int"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerBusinessListingSpecResponse = {
  __typename?: "CreateCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerCampaignInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  campaignCategoryId: Scalars["String"];
  customerId: Scalars["Int"];
  endsAt?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  partnerPackageId?: InputMaybe<Scalars["Int"]>;
  startsAt: Scalars["DateTime"];
};

export type CreateCustomerCampaignResponse = {
  __typename?: "CreateCustomerCampaignResponse";
  customerCampaign: CustomerCampaign;
};

export type CreateCustomerConversationInput = {
  content: Scalars["String"];
  customerId: Scalars["Int"];
  subject: Scalars["String"];
};

export type CreateCustomerConversationResponse = {
  __typename?: "CreateCustomerConversationResponse";
  customerConversation: CustomerConversation;
};

export type CreateCustomerHoursOfOperationItemInput = {
  closeAt: Scalars["String"];
  customerId: Scalars["Int"];
  dayIndex: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CreateCustomerInput = {
  accountManagerUserId?: InputMaybe<Scalars["Int"]>;
  categoryId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gbpUrl?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  partnerPortal?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerInvoiceInput = {
  customerId: Scalars["Int"];
  orderIds: Array<Scalars["Int"]>;
};

export type CreateCustomerInvoiceResponse = {
  __typename?: "CreateCustomerInvoiceResponse";
  customerInvoice?: Maybe<CustomerInvoice>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  isPrimary: Scalars["Boolean"];
  isTrackingRank: Scalars["Boolean"];
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateCustomerKeywordResponse = {
  __typename?: "CreateCustomerKeywordResponse";
  customerKeyword: CustomerKeyword;
};

export type CreateCustomerLinkBuildInput = {
  customerId: Scalars["Int"];
  orderId: Scalars["Int"];
  summary: Scalars["String"];
};

export type CreateCustomerLinkBuildResponse = {
  __typename?: "CreateCustomerLinkBuildResponse";
  customerLinkBuild?: Maybe<CustomerLinkBuild>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerMessageInput = {
  content: Scalars["String"];
  conversationId: Scalars["String"];
};

export type CreateCustomerMessageResponse = {
  __typename?: "CreateCustomerMessageResponse";
  customerMessage: CustomerMessage;
};

export type CreateCustomerNoteInput = {
  content: Scalars["String"];
  customerId: Scalars["Int"];
};

export type CreateCustomerPremiumBusinessListingSpecImageInput = {
  customerId: Scalars["Int"];
  fileId: Scalars["String"];
  imageTypeId: Scalars["String"];
};

export type CreateCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "CreateCustomerPremiumBusinessListingSpecImageResponse";
  customerPremiumBusinessListingSpecImage: CustomerPremiumBusinessListingSpecImage;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerResponse = {
  __typename?: "CreateCustomerResponse";
  customer?: Maybe<Customer>;
};

export type CreateCustomerSiteAuditInput = {
  customerId: Scalars["Int"];
  fileId?: InputMaybe<Scalars["String"]>;
  orderId: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerSiteAuditResponse = {
  __typename?: "CreateCustomerSiteAuditResponse";
  customerSiteAudit?: Maybe<CustomerSiteAudit>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerStatusChangeInput = {
  customerId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  reason?: InputMaybe<Scalars["String"]>;
  statusId: Scalars["String"];
};

export type CreateCustomerStatusChangeResponse = {
  __typename?: "CreateCustomerStatusChangeResponse";
  customerStatusChange: CustomerStatusChange;
};

export type CreateCustomerUserInput = {
  customerId: Scalars["Int"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type CreateCustomerUserResponse = {
  __typename?: "CreateCustomerUserResponse";
  customerUser?: Maybe<CustomerUser>;
};

export type CreateCustomerUserSsoCodeInput = {
  customerUserId: Scalars["Int"];
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CreateCustomerUserSsoCodeResponse = {
  __typename?: "CreateCustomerUserSsoCodeResponse";
  customerUserSsoCode: CustomerUserSsoCode;
};

export type CreateDomainCategoryInput = {
  categoryId: Scalars["Int"];
  domainId: Scalars["String"];
};

export type CreateDomainCategoryResponse = {
  __typename?: "CreateDomainCategoryResponse";
  domainCategory?: Maybe<DomainCategory>;
};

export type CreateDomainInput = {
  id: Scalars["String"];
};

export type CreateDomainResponse = {
  __typename?: "CreateDomainResponse";
  domain?: Maybe<Domain>;
};

export type CreateDomainsInput = {
  ids: Array<Scalars["String"]>;
};

export type CreateDomainsResponse = {
  __typename?: "CreateDomainsResponse";
  domains: Array<Domain>;
};

export type CreateFeedbackContentItemInput = {
  contentItemId: Scalars["Int"];
  feedback?: InputMaybe<Scalars["String"]>;
  rating: Scalars["Int"];
};

export type CreateFeedbackContentItemResponse = {
  __typename?: "CreateFeedbackContentItemResponse";
  error?: Maybe<ResponseError>;
  feedbackContentItem?: Maybe<FeedbackContentItem>;
  ok: Scalars["Boolean"];
};

export type CreateFulfillmentRuleInput = {
  partnerId?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  vendorId: Scalars["Int"];
};

export type CreateFulfillmentRuleResponse = {
  __typename?: "CreateFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  fulfillmentRule?: Maybe<FulfillmentRule>;
  ok: Scalars["Boolean"];
};

export type CreateLinkLocationInput = {
  allowsCbdContent?: InputMaybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: InputMaybe<Scalars["Boolean"]>;
  authorityScore?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  contactPhone?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  domain: Scalars["String"];
  domainAuthority: Scalars["Int"];
  internalQuality?: InputMaybe<Scalars["Int"]>;
  minimumWordCount?: InputMaybe<Scalars["Int"]>;
  monthlyTraffic?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  spam?: InputMaybe<Scalars["Int"]>;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type CreateLinkLocationResponse = {
  __typename?: "CreateLinkLocationResponse";
  error?: Maybe<ResponseError>;
  linkLocation?: Maybe<LinkLocation>;
  ok: Scalars["Boolean"];
};

export type CreateNoteResponse = {
  __typename?: "CreateNoteResponse";
  error?: Maybe<ResponseError>;
  note?: Maybe<Note>;
  ok: Scalars["Boolean"];
};

export type CreateOrderExternalContentSpecInput = {
  cost: Scalars["Float"];
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  externalOrderId: Scalars["String"];
  orderId: Scalars["Int"];
  submittedAt: Scalars["DateTime"];
  trustFlow?: InputMaybe<Scalars["Int"]>;
  vendorId: Scalars["Int"];
};

export type CreateOrderExternalContentSpecResponse = {
  __typename?: "CreateOrderExternalContentSpecResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
};

export type CreateOrderHaloKeywordInput = {
  customerKeywordId: Scalars["Int"];
  customerProposalId?: InputMaybe<Scalars["Int"]>;
  isProposed: Scalars["Boolean"];
  orderId: Scalars["Int"];
};

export type CreateOrderHaloKeywordResponse = {
  __typename?: "CreateOrderHaloKeywordResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderHaloKeyword?: Maybe<OrderHaloKeyword>;
};

export type CreateOrderInput = {
  customerCampaignId: Scalars["Int"];
  customerId: Scalars["Int"];
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  partnerProductId: Scalars["Int"];
  quantity?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  startImmediately?: InputMaybe<Scalars["Boolean"]>;
  topic?: InputMaybe<Scalars["String"]>;
  trackingId?: InputMaybe<Scalars["String"]>;
};

export type CreateOrderResponse = {
  __typename?: "CreateOrderResponse";
  order?: Maybe<Order>;
};

export type CreateOrdersInput = {
  orders?: InputMaybe<Array<OrderInput>>;
};

export type CreateOrdersResponse = {
  __typename?: "CreateOrdersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type CreatePartnerAvailableProductInput = {
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesalePrice: Scalars["Float"];
};

export type CreatePartnerAvailableProductResponse = {
  __typename?: "CreatePartnerAvailableProductResponse";
  partnerAvailableProduct: PartnerAvailableProduct;
};

export type CreatePartnerBillingContactInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreatePartnerBillingContactResponse = {
  __typename?: "CreatePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerBillingContact?: Maybe<PartnerBillingContact>;
};

export type CreatePartnerInput = {
  billingEmail?: InputMaybe<Scalars["String"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired: Scalars["Boolean"];
  name: Scalars["String"];
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePartnerInvoiceInput = {
  orderIds: Array<Scalars["Int"]>;
  partnerId: Scalars["Int"];
};

export type CreatePartnerInvoiceResponse = {
  __typename?: "CreatePartnerInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerInvoice?: Maybe<PartnerInvoice>;
};

export type CreatePartnerPackageInput = {
  monthlySpend: Scalars["Float"];
  months: Scalars["Int"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreatePartnerPackageItemInput = {
  isRecurring: Scalars["Boolean"];
  monthIndex: Scalars["Int"];
  partnerPackageId: Scalars["Int"];
  partnerProductId: Scalars["Int"];
  quantity: Scalars["Int"];
};

export type CreatePartnerPackageItemResponse = {
  __typename?: "CreatePartnerPackageItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackageItem?: Maybe<PartnerPackageItem>;
};

export type CreatePartnerPackageResponse = {
  __typename?: "CreatePartnerPackageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackage?: Maybe<PartnerPackage>;
};

export type CreatePartnerPaymentMethodSetupIntentInput = {
  partnerId: Scalars["Int"];
};

export type CreatePartnerPaymentMethodSetupIntentResponse = {
  __typename?: "CreatePartnerPaymentMethodSetupIntentResponse";
  clientSecret?: Maybe<Scalars["String"]>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreatePartnerProductInput = {
  activeAt?: InputMaybe<Scalars["DateTime"]>;
  checklistDefinitionId?: InputMaybe<Scalars["Int"]>;
  inactiveAt?: InputMaybe<Scalars["DateTime"]>;
  initialPrice: Scalars["Float"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type CreatePartnerProductPriceInput = {
  partnerProductId: Scalars["Int"];
  price: Scalars["Float"];
  startsAt: Scalars["DateTime"];
};

export type CreatePartnerProductPriceResponse = {
  __typename?: "CreatePartnerProductPriceResponse";
  partnerProductPrice: PartnerProductPrice;
};

export type CreatePartnerProductResponse = {
  __typename?: "CreatePartnerProductResponse";
  partnerProduct?: Maybe<PartnerProduct>;
};

export type CreatePartnerProductWholesalePriceInput = {
  effectiveAt: Scalars["DateTime"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesaleAmount: Scalars["Float"];
};

export type CreatePartnerProductWholesalePriceResponse = {
  __typename?: "CreatePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
};

export type CreatePartnerResponse = {
  __typename?: "CreatePartnerResponse";
  partner?: Maybe<Partner>;
};

export type CreatePartnerTaskTypeVendorInput = {
  partnerId: Scalars["Int"];
  taskTypeId: Scalars["String"];
  vendorId: Scalars["Int"];
};

export type CreatePartnerTaskTypeVendorResponse = {
  __typename?: "CreatePartnerTaskTypeVendorResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerTaskTypeVendor?: Maybe<PartnerTaskTypeVendor>;
};

export type CreatePartnerUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAdmin: Scalars["Boolean"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
  password?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePartnerUserResponse = {
  __typename?: "CreatePartnerUserResponse";
  partnerUser?: Maybe<PartnerUser>;
};

export type CreateProposedCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  customerKeywordProposalId: Scalars["Int"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateProposedCustomerKeywordResponse = {
  __typename?: "CreateProposedCustomerKeywordResponse";
  proposedCustomerKeyword: ProposedCustomerKeyword;
};

export type CreatePublisherInput = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isInvoiceRequired?: InputMaybe<Scalars["Boolean"]>;
  isSlowPartner?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  paymentMethod?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePublisherLinkLocationInput = {
  isRCMPublishing: Scalars["Boolean"];
  linkLocationId: Scalars["Int"];
  publisherId: Scalars["Int"];
};

export type CreatePublisherLinkLocationResponse = {
  __typename?: "CreatePublisherLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
};

export type CreatePublisherPaymentInput = {
  amount: Scalars["Float"];
  orderId: Scalars["Int"];
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["String"]>;
};

export type CreatePublisherPaymentResponse = {
  __typename?: "CreatePublisherPaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherPayment: PublisherPayment;
};

export type CreatePublisherResponse = {
  __typename?: "CreatePublisherResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisher?: Maybe<Publisher>;
};

export type CreatePublisherUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
};

export type CreatePublisherUserResponse = {
  __typename?: "CreatePublisherUserResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherUser: PublisherUser;
};

export type CreateSiteInput = {
  id: Scalars["String"];
};

export type CreateSitePostInput = {
  categoryId?: InputMaybe<Scalars["Int"]>;
  content: Scalars["String"];
  siteId?: InputMaybe<Scalars["String"]>;
  statusId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CreateSitePostResponse = {
  __typename?: "CreateSitePostResponse";
  sitePost: SitePost;
};

export type CreateSiteResponse = {
  __typename?: "CreateSiteResponse";
  site: Site;
};

export type CreateUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  isSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
};

export type CreateUserInteractionEventInput = {
  entityId?: InputMaybe<Scalars["Int"]>;
  entityName: Scalars["String"];
  eventData?: InputMaybe<Scalars["String"]>;
  eventType: Scalars["String"];
  userId: Scalars["Int"];
};

export type CreateUserInteractionEventResponse = {
  __typename?: "CreateUserInteractionEventResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type CreateUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type CreateUserProfessionalResponse = {
  __typename?: "CreateUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userProfessional: UserProfessional;
};

export type CreateUserResponse = {
  __typename?: "CreateUserResponse";
  user?: Maybe<User>;
};

export type CreateUserTaskPermissionInput = {
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type CreateUserTaskPermissionResponse = {
  __typename?: "CreateUserTaskPermissionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userTaskPermission?: Maybe<UserTaskPermission>;
};

export type CreateVendorInput = {
  isExternal?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  partnerId?: InputMaybe<Scalars["Int"]>;
};

export type CreateVendorResponse = {
  __typename?: "CreateVendorResponse";
  vendor?: Maybe<Vendor>;
};

export type CreateVendorUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  vendorId: Scalars["Int"];
};

export type CreateVendorUserResponse = {
  __typename?: "CreateVendorUserResponse";
  vendorUser?: Maybe<VendorUser>;
};

export type CreateWorkflowNoteInput = {
  content: Scalars["String"];
  workflowId: Scalars["Int"];
};

export type CreateWritingSampleTopicInput = {
  additionalInformation?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["Int"];
  isActive: Scalars["Boolean"];
  primaryKeyword: Scalars["String"];
  topic: Scalars["String"];
  voice: Scalars["String"];
  website: Scalars["String"];
};

export type CreateWritingSampleTopicResponse = {
  __typename?: "CreateWritingSampleTopicResponse";
  writingSampleTopic: WritingSampleTopic;
};

export type CreativesScaleConfiguration = {
  __typename?: "CreativesScaleConfiguration";
  workInterests?: Maybe<Array<WorkInterest>>;
};

export type CreativesScaleTaskStatus = {
  __typename?: "CreativesScaleTaskStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CreativesScaleUser = {
  __typename?: "CreativesScaleUser";
  activeUserGrammarTest?: Maybe<UserGrammarTest>;
  activeWritingSample?: Maybe<UserWritingSample>;
  allTimeEarnedAmount: Scalars["Float"];
  avgStarRating: Scalars["Float"];
  countryOfResidence?: Maybe<Scalars["String"]>;
  countryOfResidenceCountries: Array<Country>;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isAdmin: Scalars["Boolean"];
  isEligibleForPayments: Scalars["Boolean"];
  isRCMWriter: Scalars["Boolean"];
  isStripeOnboardingReminderEnabled: Scalars["Boolean"];
  latestUserGrammarTest?: Maybe<UserGrammarTest>;
  latestWritingSample?: Maybe<UserWritingSample>;
  onboardingStatus: OnboardingStatus;
  onboardingStatusId: Scalars["String"];
  onboardingStatusLastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  optedInForUpdatesAt?: Maybe<Scalars["DateTime"]>;
  payments: CreativesScaleUserPaymentConnection;
  retryGrammarTestAt?: Maybe<Scalars["DateTime"]>;
  retryWritingSampleAt?: Maybe<Scalars["DateTime"]>;
  stripeAccountId?: Maybe<Scalars["String"]>;
  stripeAccountStatus: StripeOnboardingStatus;
  unpaidBalance: Scalars["Float"];
  user: User;
  userId: Scalars["Int"];
  workInterests: Array<WorkInterest>;
  writerClassification?: Maybe<Scalars["String"]>;
  writingSampleTopics: Array<WritingSampleTopic>;
};

export type CreativesScaleUserPaymentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PaymentsSort>;
};

export type CreativesScaleUserConnection = {
  __typename?: "CreativesScaleUserConnection";
  nodes: Array<CreativesScaleUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreativesScaleUserPayment = {
  __typename?: "CreativesScaleUserPayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  creativesScaleUser: CreativesScaleUser;
  creativesScaleUserId: Scalars["Int"];
  externalPaymentId?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  paymentBatchId?: Maybe<Scalars["Int"]>;
  paymentTypeId: Scalars["String"];
  status: CreativesScaleUserPaymentStatus;
  statusId: Scalars["String"];
  statusIdUpdatedAt: Scalars["DateTime"];
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars["Int"]>;
};

export type CreativesScaleUserPaymentConnection = {
  __typename?: "CreativesScaleUserPaymentConnection";
  nodes: Array<CreativesScaleUserPayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreativesScaleUserPaymentStatus = {
  __typename?: "CreativesScaleUserPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Customer = {
  __typename?: "Customer";
  accountManagerUser?: Maybe<User>;
  accountManagerUserId?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  analyticsReportingInlineFrameUrl?: Maybe<Scalars["String"]>;
  averageRanking?: Maybe<Scalars["Float"]>;
  billableOrders: OrderConnection;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currentCustomerCampaign?: Maybe<CustomerCampaign>;
  customerBillingConfig?: Maybe<CustomerBillingConfig>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerBusinessListingSpecs: CustomerBusinessListingSpecConnection;
  customerCampaigns: CustomerCampaignConnection;
  customerHoursOfOperationItems?: Maybe<Array<CustomerHoursOfOperationItem>>;
  customerInvoices?: Maybe<CustomerInvoiceConnection>;
  customerKeywords: CustomerKeywordConnection;
  customerPremiumBusinessListingSpec?: Maybe<CustomerPremiumBusinessListingSpec>;
  customerPremiumBusinessListingSpecImages: CustomerPremiumBusinessListingSpecImageConnection;
  customerQuestionnaire?: Maybe<CustomerQuestionnaire>;
  customerSiteCredential?: Maybe<CustomerSiteCredential>;
  customerStatusChanges: CustomerStatusChangeConnection;
  customerUsers: CustomerUserConnection;
  emailMessages?: Maybe<EmailMessageConnection>;
  gbpUrl?: Maybe<Scalars["String"]>;
  hasMapRanking: Scalars["Boolean"];
  hasPremiumListing: Scalars["Boolean"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isBillingVisible: Scalars["Boolean"];
  isBlogFulfilledByRCMWriter: Scalars["Boolean"];
  isGuestArticleFulfilledByRCMWriter: Scalars["Boolean"];
  keywordsImproved?: Maybe<Scalars["Int"]>;
  keywordsOnFirstPage?: Maybe<Scalars["Int"]>;
  linkLocationsPending: LinkLocationConnection;
  linkLocationsRejected: LinkLocationConnection;
  linkLocationsUsed: LinkLocationConnection;
  name: Scalars["String"];
  notes: NoteConnection;
  partner: Partner;
  partnerId: Scalars["Int"];
  phoneNumber?: Maybe<Scalars["String"]>;
  portal: Scalars["String"];
  rcmAccountManagerUser?: Maybe<User>;
  rcmAccountManagerUserId?: Maybe<Scalars["Int"]>;
  status: CustomerStatus;
  uberallBusinessId?: Maybe<Scalars["Int"]>;
  website: Scalars["String"];
};

export type CustomerAverageRankingArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerBillableOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
};

export type CustomerCurrentCustomerCampaignArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerBusinessListingSpecsArgs = {
  isListed?: InputMaybe<Scalars["Boolean"]>;
};

export type CustomerCustomerCampaignsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerInvoicesArgs = {
  invoiceDate?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerInvoiceSort>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CustomerCustomerKeywordsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerCustomerPremiumBusinessListingSpecImagesArgs = {
  imageTypeId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerStatusChangesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  processedAt?: InputMaybe<DateQuery>;
  sort?: InputMaybe<CustomerStatusChangeSort>;
};

export type CustomerCustomerUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerUsersSort>;
};

export type CustomerEmailMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerKeywordsImprovedArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerKeywordsOnFirstPageArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerLinkLocationsPendingArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerLinkLocationsRejectedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerLinkLocationsUsedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerNotesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerBillingConfig = {
  __typename?: "CustomerBillingConfig";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  isAutoPayEnabled: Scalars["Boolean"];
  isBillingEnforced: Scalars["Boolean"];
  isUpfrontPaymentRequired: Scalars["Boolean"];
};

export type CustomerBillingContact = {
  __typename?: "CustomerBillingContact";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
};

export type CustomerBusinessListingSpec = {
  __typename?: "CustomerBusinessListingSpec";
  address: Scalars["String"];
  addressDisplay: Scalars["Boolean"];
  city: Scalars["String"];
  contactName?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  email?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  hasPhysicalStorefront: Scalars["Boolean"];
  id: Scalars["Int"];
  isListed: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  stateOrProvince: Scalars["String"];
  uberallCategoryId?: Maybe<Scalars["Int"]>;
  uberallListingDetails?: Maybe<UberallListingDetailsResponse>;
  uberallLocationId?: Maybe<Scalars["Int"]>;
  uberallSyncNeeded?: Maybe<Scalars["Boolean"]>;
  zipPostalCode: Scalars["String"];
};

export type CustomerBusinessListingSpecConnection = {
  __typename?: "CustomerBusinessListingSpecConnection";
  nodes: Array<CustomerBusinessListingSpec>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaign = {
  __typename?: "CustomerCampaign";
  addedByUserId: Scalars["Int"];
  amount: Scalars["Float"];
  campaignCategory: CampaignCategory;
  campaignCategoryId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerCampaignInvoiceCharges?: Maybe<Array<CustomerCampaignInvoiceCharge>>;
  customerId: Scalars["Int"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  name: Scalars["String"];
  pausedAt?: Maybe<Scalars["DateTime"]>;
  startsAt: Scalars["DateTime"];
};

export type CustomerCampaignConnection = {
  __typename?: "CustomerCampaignConnection";
  nodes: Array<CustomerCampaign>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoice = {
  __typename?: "CustomerCampaignInvoice";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: CustomerCampaignInvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerCampaignInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceCharge = {
  __typename?: "CustomerCampaignInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaign: CustomerCampaign;
  customerCampaignId: Scalars["Int"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  monthIndex: Scalars["Int"];
  status: CustomerCampaignInvoiceChargeStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceChargeStatus = {
  __typename?: "CustomerCampaignInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerCampaignInvoiceConnection = {
  __typename?: "CustomerCampaignInvoiceConnection";
  nodes: Array<CustomerCampaignInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoiceData = {
  __typename?: "CustomerCampaignInvoiceData";
  invoiceCharges: Array<CustomerCampaignInvoiceCharge>;
  invoicePayments: Array<CustomerCampaignInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
};

export type CustomerCampaignInvoicePayment = {
  __typename?: "CustomerCampaignInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerCampaignInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoicePaymentStatus = {
  __typename?: "CustomerCampaignInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum CustomerCampaignInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerCampaignInvoiceStatus = {
  __typename?: "CustomerCampaignInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerConnection = {
  __typename?: "CustomerConnection";
  nodes: Array<Customer>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerConversation = {
  __typename?: "CustomerConversation";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerMessages: CustomerMessageConnection;
  helpRequestedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  subject: Scalars["String"];
};

export type CustomerConversationCustomerMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CustomerMessagesSort>;
};

export type CustomerConversationConnection = {
  __typename?: "CustomerConversationConnection";
  nodes: Array<CustomerConversation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerConversationsSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export type CustomerHoursOfOperationItem = {
  __typename?: "CustomerHoursOfOperationItem";
  closeAt: Scalars["String"];
  customerId: Scalars["Int"];
  dayIndex: Scalars["Int"];
  id: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CustomerInvoice = {
  __typename?: "CustomerInvoice";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: InvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoiceCharge = {
  __typename?: "CustomerInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  status: CustomerInvoiceChargeStatus;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type CustomerInvoiceChargeStatus = {
  __typename?: "CustomerInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceConnection = {
  __typename?: "CustomerInvoiceConnection";
  nodes: Array<CustomerInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerInvoicePayment = {
  __typename?: "CustomerInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoicePaymentStatus = {
  __typename?: "CustomerInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceRefund = {
  __typename?: "CustomerInvoiceRefund";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  customerInvoicePaymentId: Scalars["Int"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
  statusId: Scalars["String"];
};

export enum CustomerInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerInvoiceStatus = {
  __typename?: "CustomerInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerKeyword = {
  __typename?: "CustomerKeyword";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  displayName: Scalars["String"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
  isTrackingMapRank: Scalars["Boolean"];
  isTrackingRank: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  latestRank?: Maybe<CustomerKeywordRanking>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  startRank?: Maybe<CustomerKeywordRanking>;
};

export type CustomerKeywordLatestRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordStartRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordConnection = {
  __typename?: "CustomerKeywordConnection";
  nodes: Array<CustomerKeyword>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerKeywordProposal = {
  __typename?: "CustomerKeywordProposal";
  createdAt: Scalars["DateTime"];
  customerConversationId?: Maybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  proposedCustomerKeywords: Array<ProposedCustomerKeyword>;
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerKeywordProposalProposedCustomerKeywordsArgs = {
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isRejected?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<ProposedCustomerKeywordSort>;
};

export type CustomerKeywordRanking = {
  __typename?: "CustomerKeywordRanking";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  rank: Scalars["Int"];
  searchEngine: Scalars["String"];
};

export type CustomerKeywordRankingConnection = {
  __typename?: "CustomerKeywordRankingConnection";
  nodes: Array<CustomerKeywordRanking>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerKeywordRankingSort {
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerLinkBuild = {
  __typename?: "CustomerLinkBuild";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerMessage = {
  __typename?: "CustomerMessage";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerConversation: CustomerConversation;
  id: Scalars["Int"];
  isFromCustomer: Scalars["Boolean"];
  user: User;
};

export type CustomerMessageConnection = {
  __typename?: "CustomerMessageConnection";
  nodes: Array<CustomerMessage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerMessagesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export type CustomerPaymentMethod = {
  __typename?: "CustomerPaymentMethod";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  externalId: Scalars["String"];
  externalProvider: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  type: Scalars["String"];
};

export type CustomerPremiumBusinessListingSpec = {
  __typename?: "CustomerPremiumBusinessListingSpec";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  hotelAmenities: Array<Scalars["String"]>;
  hotelBathroom?: Maybe<Scalars["String"]>;
  hotelFrontDesk?: Maybe<Scalars["String"]>;
  hotelHousekeeping?: Maybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: Maybe<Scalars["String"]>;
  hotelMinimumStay?: Maybe<Scalars["String"]>;
  hotelOnSiteStaff?: Maybe<Scalars["Boolean"]>;
  hotelSecurity?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAlwaysOpen?: Maybe<Scalars["Boolean"]>;
  isBlackOwned?: Maybe<Scalars["Boolean"]>;
  isWomenOwned?: Maybe<Scalars["Boolean"]>;
  priceRangeLevel?: Maybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: Maybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: Maybe<Scalars["String"]>;
  yearsInBusiness?: Maybe<Scalars["Int"]>;
};

export type CustomerPremiumBusinessListingSpecImage = {
  __typename?: "CustomerPremiumBusinessListingSpecImage";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  file: File;
  fileId: Scalars["String"];
  id: Scalars["Int"];
  imageTypeId: Scalars["String"];
};

export type CustomerPremiumBusinessListingSpecImageConnection = {
  __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
  nodes: Array<CustomerPremiumBusinessListingSpecImage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerProposal = {
  __typename?: "CustomerProposal";
  id: Scalars["Int"];
  orderContentStrategies: Array<OrderContentStrategy>;
  orderHaloKeywords: Array<OrderHaloKeyword>;
  proposedOrderTopics: Array<ProposedOrderTopic>;
  summary?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type CustomerProposalOrderContentStrategiesArgs = {
  sort?: InputMaybe<OrderContentStrategiesSort>;
};

export type CustomerQuestionnaire = {
  __typename?: "CustomerQuestionnaire";
  businessDescription?: Maybe<Scalars["String"]>;
  callToActionUrls?: Maybe<Scalars["String"]>;
  competitorWebsites?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  contentPreference?: Maybe<Scalars["String"]>;
  contentPublishingPermissions?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerMarketSize?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAddImage?: Maybe<Scalars["Boolean"]>;
  isRCMPublishing?: Maybe<Scalars["Boolean"]>;
  preferredVoice?: Maybe<Scalars["String"]>;
  productsOffered?: Maybe<Scalars["String"]>;
  purposeOfContent?: Maybe<Scalars["String"]>;
  serviceArea?: Maybe<Scalars["String"]>;
  targetArea?: Maybe<Scalars["String"]>;
  targetAudience?: Maybe<Scalars["String"]>;
  targetInternalLinks?: Maybe<Scalars["String"]>;
  targetKeywords?: Maybe<Scalars["String"]>;
  websiteCms?: Maybe<Scalars["String"]>;
  websiteHosting?: Maybe<Scalars["String"]>;
  whoCanChangeWebsite?: Maybe<Scalars["String"]>;
};

export type CustomerSiteAudit = {
  __typename?: "CustomerSiteAudit";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  file?: Maybe<File>;
  fileId?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerSiteCredential = {
  __typename?: "CustomerSiteCredential";
  adminUrl: Scalars["String"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  user: Scalars["String"];
};

export type CustomerStatus = {
  __typename?: "CustomerStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerStatusChange = {
  __typename?: "CustomerStatusChange";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  customer: Customer;
  customerId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  id: Scalars["Int"];
  reason?: Maybe<Scalars["String"]>;
  status: CustomerStatus;
  statusId: Scalars["String"];
};

export type CustomerStatusChangeConnection = {
  __typename?: "CustomerStatusChangeConnection";
  nodes: Array<CustomerStatusChange>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerStatusChangeSort {
  EffectiveAtAsc = "EFFECTIVE_AT_ASC",
  EffectiveAtDesc = "EFFECTIVE_AT_DESC",
}

export type CustomerUser = {
  __typename?: "CustomerUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  customerUserSsoCodes: CustomerUserSsoCodeConnection;
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  user: User;
  userId: Scalars["Int"];
};

export type CustomerUserCustomerUserSsoCodesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerUserConnection = {
  __typename?: "CustomerUserConnection";
  nodes: Array<CustomerUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerUserSsoCode = {
  __typename?: "CustomerUserSsoCode";
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerUserId: Scalars["Int"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  url: Scalars["String"];
};

export type CustomerUserSsoCodeConnection = {
  __typename?: "CustomerUserSsoCodeConnection";
  nodes: Array<CustomerUserSsoCode>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerUsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export enum CustomersKeywordSort {
  CurrentRankAsc = "CURRENT_RANK_ASC",
  CurrentRankDesc = "CURRENT_RANK_DESC",
  IsPrimaryAsc = "IS_PRIMARY_ASC",
  IsPrimaryDesc = "IS_PRIMARY_DESC",
  LatestRankAsc = "LATEST_RANK_ASC",
  LatestRankDesc = "LATEST_RANK_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export enum CustomersSort {
  EndDateAsc = "END_DATE_ASC",
  EndDateDesc = "END_DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type DateQuery = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  orIsNull?: InputMaybe<Scalars["Boolean"]>;
};

export type DeleteAmTaskInput = {
  amTaskId: Scalars["Int"];
};

export type DeleteAmTaskResponse = {
  __typename?: "DeleteAmTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerCampaignInput = {
  customerCampaignId: Scalars["Int"];
};

export type DeleteCustomerCampaignInvoiceInput = {
  customerCampaignInvoiceId: Scalars["Int"];
};

export type DeleteCustomerCampaignInvoiceResponse = {
  __typename?: "DeleteCustomerCampaignInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerCampaignResponse = {
  __typename?: "DeleteCustomerCampaignResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageInput = {
  customerPremiumBusinessListingSpecImageId: Scalars["Int"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "DeleteCustomerPremiumBusinessListingSpecImageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerStatusChangeInput = {
  customerStatusChangeId: Scalars["Int"];
};

export type DeleteCustomerStatusChangeResponse = {
  __typename?: "DeleteCustomerStatusChangeResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteDomainCategoryInput = {
  id: Scalars["Int"];
};

export type DeleteDomainCategoryResponse = {
  __typename?: "DeleteDomainCategoryResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteFulfillmentRuleInput = {
  fulfillmentRuleId: Scalars["Int"];
};

export type DeleteFulfillmentRuleResponse = {
  __typename?: "DeleteFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteLinkLocationInput = {
  linkLocationId: Scalars["Int"];
};

export type DeleteLinkLocationResponse = {
  __typename?: "DeleteLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteOrderContentStrategyInput = {
  orderContentStrategyId: Scalars["Int"];
};

export type DeleteOrderContentStrategyResponse = {
  __typename?: "DeleteOrderContentStrategyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteOrderInput = {
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  orderId: Scalars["Int"];
};

export type DeleteOrderResponse = {
  __typename?: "DeleteOrderResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeletePartnerBillingContactInput = {
  partnerBillingContactId: Scalars["Int"];
};

export type DeletePartnerBillingContactResponse = {
  __typename?: "DeletePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerPaymentMethodInput = {
  partnerPaymentMethodId: Scalars["Int"];
};

export type DeletePartnerPaymentMethodResponse = {
  __typename?: "DeletePartnerPaymentMethodResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerProductInput = {
  partnerProductId: Scalars["Int"];
};

export type DeletePartnerProductPriceInput = {
  partnerProductPriceId: Scalars["Int"];
};

export type DeletePartnerProductPriceResponse = {
  __typename?: "DeletePartnerProductPriceResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeletePartnerProductResponse = {
  __typename?: "DeletePartnerProductResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerProductWholesalePriceInput = {
  partnerProductWholesalePriceId: Scalars["Int"];
};

export type DeletePartnerProductWholesalePriceResponse = {
  __typename?: "DeletePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerTaskTypeVendorInput = {
  partnerTaskTypeVendorId: Scalars["Int"];
};

export type DeletePartnerTaskTypeVendorResponse = {
  __typename?: "DeletePartnerTaskTypeVendorResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteProposedCustomerKeywordInput = {
  proposedCustomerKeywordId: Scalars["Int"];
};

export type DeleteProposedCustomerKeywordResponse = {
  __typename?: "DeleteProposedCustomerKeywordResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteSitePostInput = {
  sitePostId: Scalars["Int"];
};

export type DeleteSitePostResponse = {
  __typename?: "DeleteSitePostResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteUserTaskPermissionInput = {
  userTaskPermissionId: Scalars["Int"];
};

export type DeleteUserTaskPermissionResponse = {
  __typename?: "DeleteUserTaskPermissionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Domain = {
  __typename?: "Domain";
  availabilityLastCheckedAt?: Maybe<Scalars["DateTime"]>;
  canRefreshAvailability: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  domainCategories: DomainCategoryConnection;
  expiresAt?: Maybe<Scalars["Date"]>;
  id: Scalars["String"];
  purchaseError?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["Float"]>;
  purchaseRequestedByUser?: Maybe<User>;
  purchaseRequestedByUserId?: Maybe<Scalars["Int"]>;
  purchasedAt?: Maybe<Scalars["DateTime"]>;
  registrar?: Maybe<Scalars["String"]>;
  registrarId?: Maybe<Scalars["String"]>;
  site?: Maybe<Site>;
  status: DomainStatus;
};

export type DomainDomainCategoriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type DomainCategory = {
  __typename?: "DomainCategory";
  category?: Maybe<Category>;
  categoryId: Scalars["Int"];
  domain?: Maybe<Domain>;
  domainId: Scalars["String"];
  id: Scalars["Int"];
};

export type DomainCategoryConnection = {
  __typename?: "DomainCategoryConnection";
  nodes: Array<DomainCategory>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type DomainConnection = {
  __typename?: "DomainConnection";
  nodes: Array<Domain>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type DomainMetrics = {
  __typename?: "DomainMetrics";
  authorityScore: Scalars["Int"];
  domainAuthority: Scalars["Int"];
  organitcTraffic: Scalars["Int"];
  spamScore: Scalars["Int"];
};

export type DomainMetricsRespose = {
  __typename?: "DomainMetricsRespose";
  domainMetrics?: Maybe<DomainMetrics>;
};

export type DomainStatus = {
  __typename?: "DomainStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum DomainsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type EmailMessage = {
  __typename?: "EmailMessage";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId?: Maybe<Scalars["Int"]>;
  fromAddress: Scalars["String"];
  fromName: Scalars["String"];
  id: Scalars["Int"];
  partnerId?: Maybe<Scalars["Int"]>;
  sentAt?: Maybe<Scalars["DateTime"]>;
  subject: Scalars["String"];
  toAddress: Scalars["String"];
  toName: Scalars["String"];
  userId?: Maybe<Scalars["Int"]>;
};

export type EmailMessageConnection = {
  __typename?: "EmailMessageConnection";
  nodes: Array<EmailMessage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type EnableAutopayInformation = {
  __typename?: "EnableAutopayInformation";
  dueImmediatelyAmount?: Maybe<Scalars["Float"]>;
  nextBillingAmount?: Maybe<Scalars["Float"]>;
  nextBillingDate?: Maybe<Scalars["DateTime"]>;
};

export type FeedbackContentItem = {
  __typename?: "FeedbackContentItem";
  contentItem: ContentItem;
  contentItemId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  feedback: Scalars["String"];
  id: Scalars["Int"];
  rating: Scalars["Int"];
  reviewUser: User;
  reviewerUserId: Scalars["Int"];
};

export type File = {
  __typename?: "File";
  contentType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
  url: Scalars["String"];
};

export type FulfillmentRule = {
  __typename?: "FulfillmentRule";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partnerId?: Maybe<Scalars["Int"]>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Vendor>;
  vendorId: Scalars["Int"];
};

export type FulfillmentRuleConnection = {
  __typename?: "FulfillmentRuleConnection";
  nodes: Array<FulfillmentRule>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type GbpItem = {
  __typename?: "GbpItem";
  content: Scalars["String"];
  id: Scalars["Int"];
  readMoreUrl?: Maybe<Scalars["String"]>;
  thumbnailFile?: Maybe<File>;
};

export type GenerateUrlForFileUploadInput = {
  contentType: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
};

export type GenerateUrlForFileUploadResponse = {
  __typename?: "GenerateUrlForFileUploadResponse";
  expiresAt: Scalars["DateTime"];
  fileId: Scalars["String"];
  url: Scalars["String"];
};

export type GenerateUrlForFileUploadSignupInput = {
  contentType: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
};

export type GenerateUrlForFileUploadSignupResponse = {
  __typename?: "GenerateUrlForFileUploadSignupResponse";
  expiresAt: Scalars["DateTime"];
  fileId: Scalars["String"];
  url: Scalars["String"];
};

export type GenerateUrlForStripeDashboardResponse = {
  __typename?: "GenerateUrlForStripeDashboardResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type GenerateUrlForStripeOnboardingResponse = {
  __typename?: "GenerateUrlForStripeOnboardingResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type GoogleAccount = {
  __typename?: "GoogleAccount";
  accountName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  verificationState?: Maybe<Scalars["String"]>;
  vettedState?: Maybe<Scalars["String"]>;
};

export type GoogleAccountsResponse = {
  __typename?: "GoogleAccountsResponse";
  accounts?: Maybe<Array<GoogleAccount>>;
};

export type GoogleLocation = {
  __typename?: "GoogleLocation";
  name?: Maybe<Scalars["String"]>;
  storeCode?: Maybe<Scalars["String"]>;
  storefrontAddress?: Maybe<PostalAddress>;
  title?: Maybe<Scalars["String"]>;
  websiteUri?: Maybe<Scalars["String"]>;
};

export type GoogleLocationsResponse = {
  __typename?: "GoogleLocationsResponse";
  locations?: Maybe<Array<GoogleLocation>>;
};

export type GoogleReview = {
  __typename?: "GoogleReview";
  comment?: Maybe<Scalars["String"]>;
  createTime?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  reviewId?: Maybe<Scalars["String"]>;
  reviewReply?: Maybe<ReviewReply>;
  reviewer?: Maybe<Reviewer>;
  starRating?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
};

export type GoogleReviewReplyInput = {
  account?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  reviewId?: InputMaybe<Scalars["String"]>;
};

export type GoogleReviewReplyResponse = {
  __typename?: "GoogleReviewReplyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type GoogleReviewRespose = {
  __typename?: "GoogleReviewRespose";
  averageRating?: Maybe<Scalars["Float"]>;
  nextPageToken?: Maybe<Scalars["String"]>;
  reviews?: Maybe<Array<GoogleReview>>;
  totalReviewCount?: Maybe<Scalars["Int"]>;
};

export type GrammarTestQuestion = {
  __typename?: "GrammarTestQuestion";
  answers: Array<GrammarTestQuestionAnswer>;
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  prompt: Scalars["String"];
  totalAttempts: Scalars["Int"];
  totalCorrectAttempts: Scalars["Int"];
};

export type GrammarTestQuestionAnswer = {
  __typename?: "GrammarTestQuestionAnswer";
  content: Scalars["String"];
  grammarTestQuestionId: Scalars["Int"];
  id: Scalars["Int"];
  isCorrect?: Maybe<Scalars["Boolean"]>;
};

export type GrammarTestQuestionAnswerInput = {
  content: Scalars["String"];
  id?: InputMaybe<Scalars["Int"]>;
  isCorrect: Scalars["Boolean"];
};

export type GrammarTestQuestionConnection = {
  __typename?: "GrammarTestQuestionConnection";
  nodes: Array<GrammarTestQuestion>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ImportInvoicesInput = {
  content: Scalars["String"];
};

export type ImportInvoicesResponse = {
  __typename?: "ImportInvoicesResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  results: Array<ImportInvoicesResult>;
};

export type ImportInvoicesResult = {
  __typename?: "ImportInvoicesResult";
  message: Scalars["String"];
  ok: Scalars["Boolean"];
  orderId: Scalars["Int"];
};

export type InputFieldError = {
  __typename?: "InputFieldError";
  fieldName: Scalars["String"];
  message: Scalars["String"];
};

export type InvoiceData = {
  __typename?: "InvoiceData";
  invoiceCharges: Array<CustomerInvoiceCharge>;
  invoicePayments: Array<CustomerInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
  totalRefundsAmount: Scalars["Float"];
};

export type KeywordLocation = {
  __typename?: "KeywordLocation";
  country_iso_code: Scalars["String"];
  location_code: Scalars["Int"];
  location_code_parent?: Maybe<Scalars["Int"]>;
  location_name: Scalars["String"];
  location_type: Scalars["String"];
};

export type KeywordSuggestion = {
  __typename?: "KeywordSuggestion";
  avgMonthlySearches?: Maybe<Scalars["Int"]>;
  competitionIndex?: Maybe<Scalars["Int"]>;
  keyword: Scalars["String"];
};

export type KeywordsSuggestionRespose = {
  __typename?: "KeywordsSuggestionRespose";
  keywordSuggestions?: Maybe<Array<KeywordSuggestion>>;
};

export type LinkLocation = {
  __typename?: "LinkLocation";
  allowsCbdContent?: Maybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: Maybe<Scalars["Boolean"]>;
  authorityScore?: Maybe<Scalars["Int"]>;
  contactEmail?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  contactPhone?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["DateTime"];
  domain: Scalars["String"];
  domainAuthority: Scalars["Int"];
  id: Scalars["Int"];
  internalQuality?: Maybe<Scalars["Int"]>;
  lastMetricsUpdatedAt?: Maybe<Scalars["DateTime"]>;
  linkLocationCategories: Array<LinkLocationCategory>;
  linkLocationCredential?: Maybe<LinkLocationCredential>;
  minimumWordCount?: Maybe<Scalars["Int"]>;
  monthlyTraffic?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  publishedOrders: OrderConnection;
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
  spam?: Maybe<Scalars["Int"]>;
  status: LinkLocationStatus;
  statusId: Scalars["String"];
  type: LinkLocationType;
  typeId: Scalars["String"];
};

export type LinkLocationCategory = {
  __typename?: "LinkLocationCategory";
  category: Category;
  categoryId: Scalars["Int"];
  id: Scalars["Int"];
  linkLocation: LinkLocation;
  linkLocationId: Scalars["Int"];
};

export type LinkLocationConnection = {
  __typename?: "LinkLocationConnection";
  nodes: Array<LinkLocation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type LinkLocationCredential = {
  __typename?: "LinkLocationCredential";
  adminUrl: Scalars["String"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type LinkLocationStatus = {
  __typename?: "LinkLocationStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type LinkLocationType = {
  __typename?: "LinkLocationType";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum LinkLocationsSort {
  CostAsc = "cost_asc",
  CostDesc = "cost_desc",
  DaAsc = "da_asc",
  DaDesc = "da_desc",
  IdAsc = "id_asc",
  IdDesc = "id_desc",
  TrafficAsc = "traffic_asc",
  TrafficDesc = "traffic_desc",
}

export type LocationResponse = {
  __typename?: "LocationResponse";
  nodes: Array<KeywordLocation>;
};

export type LoginGoogleCallbackInput = {
  code: Scalars["String"];
};

export type LoginGoogleCallbackResponse = {
  __typename?: "LoginGoogleCallbackResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type LoginGoogleResponse = {
  __typename?: "LoginGoogleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type LoginUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  googleAccessToken?: InputMaybe<Scalars["String"]>;
  magicLink?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  ssoCode?: InputMaybe<Scalars["String"]>;
  temporaryLoginCode?: InputMaybe<Scalars["String"]>;
};

export type LoginUserResponse = {
  __typename?: "LoginUserResponse";
  isLoggedIn?: Maybe<Scalars["Boolean"]>;
};

export type LogoutUserResponse = {
  __typename?: "LogoutUserResponse";
  isLoggedOut: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  approvePaymentBatchFunding: ApprovePaymentBatchFundingResponse;
  approvePaymentBatchFundingPaypal: ApprovePaymentBatchFundingPaypalResponse;
  approveUserWritingSample: ApproveUserWritingSampleResponse;
  changePassword: ChangePasswordResponse;
  checkout: CheckoutResponse;
  claimTask: ClaimTaskResponse;
  clearProposedCustomerKeywordsRejection: ClearProposedCustomerKeywordsRejectionResponse;
  confirmEmail: ConfirmEmailResponse;
  createAmTask: CreateAmTaskResponse;
  createBlockDomainCustomers: CreateBlockDomainCustomersResponse;
  createBulkProposedCustomerKeywords: CreateBulkProposedCustomerKeywordsResponse;
  createBusinessSubscriptionOrders: CreateBusinessSubscriptionOrdersResponse;
  createCategory: CreateCategoryResponse;
  createChecklistDefinition: CreateChecklistDefinitionResponse;
  createCustomer: CreateCustomerResponse;
  createCustomerBusinessListingSpec: CreateCustomerBusinessListingSpecResponse;
  createCustomerCampaign: CreateCustomerCampaignResponse;
  createCustomerConversation: CreateCustomerConversationResponse;
  createCustomerInvoice: CreateCustomerInvoiceResponse;
  createCustomerKeyword: CreateCustomerKeywordResponse;
  createCustomerLinkBuild: CreateCustomerLinkBuildResponse;
  createCustomerMessage: CreateCustomerMessageResponse;
  createCustomerNote: CreateNoteResponse;
  createCustomerPremiumBusinessListingSpecImage: CreateCustomerPremiumBusinessListingSpecImageResponse;
  createCustomerSiteAudit: CreateCustomerSiteAuditResponse;
  createCustomerStatusChange: CreateCustomerStatusChangeResponse;
  createCustomerUser: CreateCustomerUserResponse;
  createCustomerUserSsoCode: CreateCustomerUserSsoCodeResponse;
  createDomain: CreateDomainResponse;
  createDomainCategory: CreateDomainCategoryResponse;
  createDomains: CreateDomainsResponse;
  createFeedbackContentItem: CreateFeedbackContentItemResponse;
  createFulfillmentRule: CreateFulfillmentRuleResponse;
  createLinkLocation: CreateLinkLocationResponse;
  createOrder: CreateOrderResponse;
  createOrderExternalContentSpec: CreateOrderExternalContentSpecResponse;
  createOrderHaloKeyword: CreateOrderHaloKeywordResponse;
  createOrders: CreateOrdersResponse;
  createPartner: CreatePartnerResponse;
  createPartnerAvailableProduct: CreatePartnerAvailableProductResponse;
  createPartnerBillingContact: CreatePartnerBillingContactResponse;
  createPartnerInvoice: CreatePartnerInvoiceResponse;
  createPartnerPackage: CreatePartnerPackageResponse;
  createPartnerPackageItem: CreatePartnerPackageItemResponse;
  createPartnerPaymentMethodSetupIntent: CreatePartnerPaymentMethodSetupIntentResponse;
  createPartnerProduct: CreatePartnerProductResponse;
  createPartnerProductPrice: CreatePartnerProductPriceResponse;
  createPartnerProductWholesalePrice: CreatePartnerProductWholesalePriceResponse;
  createPartnerTaskTypeVendor: CreatePartnerTaskTypeVendorResponse;
  createPartnerUser: CreatePartnerUserResponse;
  createProposedCustomerKeyword: CreateProposedCustomerKeywordResponse;
  createPublisher: CreatePublisherResponse;
  createPublisherLinkLocation: CreatePublisherLinkLocationResponse;
  createPublisherPayment: CreatePublisherPaymentResponse;
  createPublisherUser: CreatePublisherUserResponse;
  createSite: CreateSiteResponse;
  createSitePost: CreateSitePostResponse;
  createUser: CreateUserResponse;
  createUserInteractionEvent: CreateUserInteractionEventResponse;
  createUserProfessional: CreateUserProfessionalResponse;
  createUserTaskPermission: CreateUserTaskPermissionResponse;
  createVendor: CreateVendorResponse;
  createVendorUser: CreateVendorUserResponse;
  createWorkflowNote: CreateNoteResponse;
  createWritingSampleTopic: CreateWritingSampleTopicResponse;
  deleteAmTask: DeleteAmTaskResponse;
  deleteCustomerCampaign: DeleteCustomerCampaignResponse;
  deleteCustomerCampaignInvoice: DeleteCustomerCampaignInvoiceResponse;
  deleteCustomerPremiumBusinessListingSpecImage: DeleteCustomerPremiumBusinessListingSpecImageResponse;
  deleteCustomerStatusChange: DeleteCustomerStatusChangeResponse;
  deleteDomainCategory: DeleteDomainCategoryResponse;
  deleteFulfillmentRule: DeleteFulfillmentRuleResponse;
  deleteLinkLocation: DeleteLinkLocationResponse;
  deleteOrder: DeleteOrderResponse;
  deleteOrderContentStrategy: DeleteOrderContentStrategyResponse;
  deletePartnerBillingContact: DeletePartnerBillingContactResponse;
  deletePartnerPaymentMethod: DeletePartnerPaymentMethodResponse;
  deletePartnerProduct: DeletePartnerProductResponse;
  deletePartnerProductPrice: DeletePartnerProductPriceResponse;
  deletePartnerProductWholesalePrice: DeletePartnerProductWholesalePriceResponse;
  deletePartnerTaskTypeVendor: DeletePartnerTaskTypeVendorResponse;
  deleteProposedCustomerKeyword: DeleteProposedCustomerKeywordResponse;
  deleteSitePost: DeleteSitePostResponse;
  deleteUserTaskPermission: DeleteUserTaskPermissionResponse;
  generateUrlForFileUpload: GenerateUrlForFileUploadResponse;
  generateUrlForFileUploadSignup: GenerateUrlForFileUploadSignupResponse;
  generateUrlForStripeDashboard: GenerateUrlForStripeDashboardResponse;
  generateUrlForStripeOnboarding: GenerateUrlForStripeOnboardingResponse;
  googleReviewReply: GoogleReviewReplyResponse;
  importInvoices: ImportInvoicesResponse;
  loginGoogleCallback: LoginGoogleCallbackResponse;
  loginGoogleService: LoginGoogleResponse;
  loginUser: LoginUserResponse;
  logoutUser: LogoutUserResponse;
  payPartnerInvoice: PayPartnerInvoiceResponse;
  purchaseDomain: PurchaseDomainResponse;
  reCreateGuestOutreachTask: ReCreateGuestOutreachTaskResponse;
  recordCustomerCampaignInvoicePayment: RecordCustomerCampaignInvoicePaymentResponse;
  recordCustomerInvoicePayment: RecordCustomerInvoicePaymentResponse;
  recordPartnerInvoicePayment: RecordPartnerInvoicePaymentResponse;
  refreshDomainAvailability: RefreshDomainAvailabilityResponse;
  rejectUserWritingSample: RejectUserWritingSampleResponse;
  requestPasswordReset: RequestPasswordResetResponse;
  resendConfirmationEmail: ResendConfirmationEmailResponse;
  resetPassword: ResetPasswordResponse;
  resolveOrderContentStrategyReview: ResolveOrderContentStrategyReviewResponse;
  saveCustomerBillingConfig: SaveCustomerBillingConfigResponse;
  saveCustomerBusinessListingSpec: SaveCustomerBusinessListingSpecResponse;
  saveCustomerHoursOfOperationItems: SaveCustomerHoursOfOperationItemsResponse;
  saveCustomerPremiumBusinessListingSpec: SaveCustomerPremiumBusinessListingSpecResponse;
  saveCustomerQuestionnaire: SaveCustomerQuestionnaireResponse;
  saveCustomerSiteCredential: SaveCustomerSiteCredentialResponse;
  saveGrammarTestQuestion: SaveGrammarTestQuestionResponse;
  saveLinkLocationCredential: SaveLinkLocationCredentialResponse;
  saveOrderContentItem: SaveOrderContentItemResponse;
  savePartnerAnalyticCredential: SavePartnerAnalyticCredentialResponse;
  saveProposedOrderTopic: SaveProposedOrderTopicResponse;
  saveTaskContentIdeation: SaveTaskContentIdeationResponse;
  saveTaskContentItem: SaveTaskContentItemResponse;
  saveTaskGbpItem: SaveTaskGbpItemResponse;
  saveTaskTitleMetaDescriptions: SaveTaskTitleMetaDescriptionResponse;
  saveUserGrammarTestResponse: SaveUserGrammarTestResponseResponse;
  saveUserProfessional: SaveUserProfessionalResponse;
  saveUserWorkInterests: SaveUserWorkInterestsResponse;
  saveWritingSample: SaveWritingSampleResponse;
  selectWritingSampleTopic: SelectWritingSampleTopicResponse;
  sendEmailManagedSeo: SendEmailManagedSeoResponse;
  signupCSUser: SignupCsUserResponse;
  startGrammarTest: StartGrammarTestResponse;
  startWritingSample: StartWritingSampleResponse;
  submitProposalReview: SubmitProposalReviewResponse;
  syncUberallLocation: SyncUberallLocationResponse;
  updateAmTask: UpdateAmTaskResponse;
  updateCategory: UpdateCategoryResponse;
  updateChecklistItem: UpdateChecklistItemResponse;
  updateCreativesScaleUser: UpdateCreativeScaleUserResponse;
  updateCustomer: UpdateCustomerResponse;
  updateCustomerBusinessListingSpec: UpdateCustomerBusinessListingSpecResponse;
  updateCustomerCampaign: UpdateCustomerCampaignResponse;
  updateCustomerConversation: UpdateCustomerConversationResponse;
  updateCustomerKeyword: UpdateCustomerKeywordResponse;
  updateCustomerKeywordProposal: UpdateCustomerKeywordProposalResponse;
  updateCustomerNote: UpdateCustomerNoteResponse;
  updateCustomerProposal: UpdateCustomerProposalResponse;
  updateCustomerStatusChange: UpdateCustomerStatusChangeResponse;
  updateCustomerUser: UpdateCustomerUserResponse;
  updateFeedbackContentItem: UpdateFeedbackContentItemResponse;
  updateFulfillmentRule: UpdateFulfillmentRuleResponse;
  updateLinkLocation: UpdateLinkLocationResponse;
  updateLinkLocationCategories: UpdateLinkLocationCategoriesResponse;
  updateLinkLocationMetrics: UpdateLinkLocationMetricsResponse;
  updateOrder: UpdateOrderResponse;
  updateOrderContentItem: UpdateOrderContentItemResponse;
  updateOrderContentStrategy: UpdateOrderContentStrategyResponse;
  updateOrderExternalContentSpec: UpdateOrderExternalContentSpecResponse;
  updateOrderGbpItem: UpdateOrderGbpItemResponse;
  updateOrderTitleMetaDescriptionItem: UpdateOrderTitleMetaDescriptionItemResponse;
  updatePartner: UpdatePartnerResponse;
  updatePartnerBillingContact: UpdatePartnerBillingContactResponse;
  updatePartnerPackage: UpdatePartnerPackageResponse;
  updatePartnerPackageItem: UpdatePartnerPackageItemResponse;
  updatePartnerProduct?: Maybe<UpdatePartnerProductResponse>;
  updatePartnerProductPrice: UpdatePartnerProductPriceResponse;
  updatePartnerProductWholesalePrice: UpdatePartnerProductWholesalePriceResponse;
  updatePartnerUser: UpdatePartnerUserResponse;
  updateProposedCustomerKeyword: UpdateProposedCustomerKeywordResponse;
  updatePublisher: UpdatePublisherResponse;
  updatePublisherLinkLocation: UpdatePublisherLinkLocationResponse;
  updatePublisherPayment: UpdatePublisherPaymentResponse;
  updateRcmWriterTasks: UpdateRcmWriterTasksResponse;
  updateSitePost: UpdateSitePostResponse;
  updateSitePostForTask: UpdateSitePostForTaskResponse;
  updateTask: UpdateTaskResponse;
  updateUser: UpdateUserResponse;
  updateUserProfessional: UpdateUserProfessionalResponse;
  updateVendor: UpdateVendorResponse;
  updateVendorUser: UpdateVendorUserResponse;
  updateWritingSampleTopic: UpdateWritingSampleTopicResponse;
  verifyGuestPost: VerifyGuestPostResponse;
  version?: Maybe<Scalars["String"]>;
};

export type MutationApprovePaymentBatchFundingArgs = {
  input: ApprovePaymentBatchFundingInput;
};

export type MutationApprovePaymentBatchFundingPaypalArgs = {
  input: ApprovePaymentBatchFundingPaypalInput;
};

export type MutationApproveUserWritingSampleArgs = {
  input: ApproveUserWritingSampleInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCheckoutArgs = {
  input: CheckoutInput;
};

export type MutationClaimTaskArgs = {
  input: ClaimTaskInput;
};

export type MutationClearProposedCustomerKeywordsRejectionArgs = {
  input: ClearProposedCustomerKeywordsRejectionInput;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationCreateAmTaskArgs = {
  input: CreateAmTaskInput;
};

export type MutationCreateBlockDomainCustomersArgs = {
  input: CreateBlockDomainCustomersInput;
};

export type MutationCreateBulkProposedCustomerKeywordsArgs = {
  input: CreateBulkProposedCustomerKeywordsInput;
};

export type MutationCreateBusinessSubscriptionOrdersArgs = {
  input: CreateBusinessSubscriptionOrdersInput;
};

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type MutationCreateChecklistDefinitionArgs = {
  input: CreateChecklistDefinitionInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateCustomerBusinessListingSpecArgs = {
  input: CreateCustomerBusinessListingSpecInput;
};

export type MutationCreateCustomerCampaignArgs = {
  input: CreateCustomerCampaignInput;
};

export type MutationCreateCustomerConversationArgs = {
  input: CreateCustomerConversationInput;
};

export type MutationCreateCustomerInvoiceArgs = {
  input: CreateCustomerInvoiceInput;
};

export type MutationCreateCustomerKeywordArgs = {
  input: CreateCustomerKeywordInput;
};

export type MutationCreateCustomerLinkBuildArgs = {
  input: CreateCustomerLinkBuildInput;
};

export type MutationCreateCustomerMessageArgs = {
  input: CreateCustomerMessageInput;
};

export type MutationCreateCustomerNoteArgs = {
  input: CreateCustomerNoteInput;
};

export type MutationCreateCustomerPremiumBusinessListingSpecImageArgs = {
  input: CreateCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationCreateCustomerSiteAuditArgs = {
  input: CreateCustomerSiteAuditInput;
};

export type MutationCreateCustomerStatusChangeArgs = {
  input: CreateCustomerStatusChangeInput;
};

export type MutationCreateCustomerUserArgs = {
  input: CreateCustomerUserInput;
};

export type MutationCreateCustomerUserSsoCodeArgs = {
  input: CreateCustomerUserSsoCodeInput;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateDomainCategoryArgs = {
  input: CreateDomainCategoryInput;
};

export type MutationCreateDomainsArgs = {
  input: CreateDomainsInput;
};

export type MutationCreateFeedbackContentItemArgs = {
  input: CreateFeedbackContentItemInput;
};

export type MutationCreateFulfillmentRuleArgs = {
  input: CreateFulfillmentRuleInput;
};

export type MutationCreateLinkLocationArgs = {
  input: CreateLinkLocationInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreateOrderExternalContentSpecArgs = {
  input: CreateOrderExternalContentSpecInput;
};

export type MutationCreateOrderHaloKeywordArgs = {
  input: CreateOrderHaloKeywordInput;
};

export type MutationCreateOrdersArgs = {
  input: CreateOrdersInput;
};

export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
};

export type MutationCreatePartnerAvailableProductArgs = {
  input: CreatePartnerAvailableProductInput;
};

export type MutationCreatePartnerBillingContactArgs = {
  input: CreatePartnerBillingContactInput;
};

export type MutationCreatePartnerInvoiceArgs = {
  input: CreatePartnerInvoiceInput;
};

export type MutationCreatePartnerPackageArgs = {
  input: CreatePartnerPackageInput;
};

export type MutationCreatePartnerPackageItemArgs = {
  input: CreatePartnerPackageItemInput;
};

export type MutationCreatePartnerPaymentMethodSetupIntentArgs = {
  input: CreatePartnerPaymentMethodSetupIntentInput;
};

export type MutationCreatePartnerProductArgs = {
  input: CreatePartnerProductInput;
};

export type MutationCreatePartnerProductPriceArgs = {
  input: CreatePartnerProductPriceInput;
};

export type MutationCreatePartnerProductWholesalePriceArgs = {
  input: CreatePartnerProductWholesalePriceInput;
};

export type MutationCreatePartnerTaskTypeVendorArgs = {
  input: CreatePartnerTaskTypeVendorInput;
};

export type MutationCreatePartnerUserArgs = {
  input: CreatePartnerUserInput;
};

export type MutationCreateProposedCustomerKeywordArgs = {
  input: CreateProposedCustomerKeywordInput;
};

export type MutationCreatePublisherArgs = {
  input: CreatePublisherInput;
};

export type MutationCreatePublisherLinkLocationArgs = {
  input: CreatePublisherLinkLocationInput;
};

export type MutationCreatePublisherPaymentArgs = {
  input: CreatePublisherPaymentInput;
};

export type MutationCreatePublisherUserArgs = {
  input: CreatePublisherUserInput;
};

export type MutationCreateSiteArgs = {
  input: CreateSiteInput;
};

export type MutationCreateSitePostArgs = {
  input: CreateSitePostInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserInteractionEventArgs = {
  input: CreateUserInteractionEventInput;
};

export type MutationCreateUserProfessionalArgs = {
  input: CreateUserProfessionalInput;
};

export type MutationCreateUserTaskPermissionArgs = {
  input: CreateUserTaskPermissionInput;
};

export type MutationCreateVendorArgs = {
  input: CreateVendorInput;
};

export type MutationCreateVendorUserArgs = {
  input: CreateVendorUserInput;
};

export type MutationCreateWorkflowNoteArgs = {
  input: CreateWorkflowNoteInput;
};

export type MutationCreateWritingSampleTopicArgs = {
  input: CreateWritingSampleTopicInput;
};

export type MutationDeleteAmTaskArgs = {
  input: DeleteAmTaskInput;
};

export type MutationDeleteCustomerCampaignArgs = {
  input: DeleteCustomerCampaignInput;
};

export type MutationDeleteCustomerCampaignInvoiceArgs = {
  input: DeleteCustomerCampaignInvoiceInput;
};

export type MutationDeleteCustomerPremiumBusinessListingSpecImageArgs = {
  input: DeleteCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationDeleteCustomerStatusChangeArgs = {
  input: DeleteCustomerStatusChangeInput;
};

export type MutationDeleteDomainCategoryArgs = {
  input: DeleteDomainCategoryInput;
};

export type MutationDeleteFulfillmentRuleArgs = {
  input: DeleteFulfillmentRuleInput;
};

export type MutationDeleteLinkLocationArgs = {
  input: DeleteLinkLocationInput;
};

export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};

export type MutationDeleteOrderContentStrategyArgs = {
  input: DeleteOrderContentStrategyInput;
};

export type MutationDeletePartnerBillingContactArgs = {
  input: DeletePartnerBillingContactInput;
};

export type MutationDeletePartnerPaymentMethodArgs = {
  input: DeletePartnerPaymentMethodInput;
};

export type MutationDeletePartnerProductArgs = {
  input: DeletePartnerProductInput;
};

export type MutationDeletePartnerProductPriceArgs = {
  input: DeletePartnerProductPriceInput;
};

export type MutationDeletePartnerProductWholesalePriceArgs = {
  input: DeletePartnerProductWholesalePriceInput;
};

export type MutationDeletePartnerTaskTypeVendorArgs = {
  input: DeletePartnerTaskTypeVendorInput;
};

export type MutationDeleteProposedCustomerKeywordArgs = {
  input: DeleteProposedCustomerKeywordInput;
};

export type MutationDeleteSitePostArgs = {
  input: DeleteSitePostInput;
};

export type MutationDeleteUserTaskPermissionArgs = {
  input: DeleteUserTaskPermissionInput;
};

export type MutationGenerateUrlForFileUploadArgs = {
  input?: InputMaybe<GenerateUrlForFileUploadInput>;
};

export type MutationGenerateUrlForFileUploadSignupArgs = {
  input?: InputMaybe<GenerateUrlForFileUploadSignupInput>;
};

export type MutationGoogleReviewReplyArgs = {
  input: GoogleReviewReplyInput;
};

export type MutationImportInvoicesArgs = {
  input: ImportInvoicesInput;
};

export type MutationLoginGoogleCallbackArgs = {
  input?: InputMaybe<LoginGoogleCallbackInput>;
};

export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

export type MutationPayPartnerInvoiceArgs = {
  input: PayPartnerInvoiceInput;
};

export type MutationPurchaseDomainArgs = {
  input: PurchaseDomainInput;
};

export type MutationReCreateGuestOutreachTaskArgs = {
  input: ReCreateGuestOutreachTaskInput;
};

export type MutationRecordCustomerCampaignInvoicePaymentArgs = {
  input: RecordCustomerCampaignInvoicePaymentInput;
};

export type MutationRecordCustomerInvoicePaymentArgs = {
  input: RecordCustomerInvoicePaymentInput;
};

export type MutationRecordPartnerInvoicePaymentArgs = {
  input: RecordPartnerInvoicePaymentInput;
};

export type MutationRefreshDomainAvailabilityArgs = {
  input?: InputMaybe<RefreshDomainAvailabilityInput>;
};

export type MutationRejectUserWritingSampleArgs = {
  input: RejectUserWritingSampleInput;
};

export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

export type MutationResendConfirmationEmailArgs = {
  input: ResendConfirmationEmailInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationResolveOrderContentStrategyReviewArgs = {
  input: ResolveOrderContentStrategyReviewInput;
};

export type MutationSaveCustomerBillingConfigArgs = {
  input: SaveCustomerBillingConfigInput;
};

export type MutationSaveCustomerBusinessListingSpecArgs = {
  input: SaveCustomerBusinessListingSpecInput;
};

export type MutationSaveCustomerHoursOfOperationItemsArgs = {
  input: SaveCustomerHoursOfOperationItemsInput;
};

export type MutationSaveCustomerPremiumBusinessListingSpecArgs = {
  input: SaveCustomerPremiumBusinessListingSpecInput;
};

export type MutationSaveCustomerQuestionnaireArgs = {
  input: SaveCustomerQuestionnaireInput;
};

export type MutationSaveCustomerSiteCredentialArgs = {
  input: SaveCustomerSiteCredentialInput;
};

export type MutationSaveGrammarTestQuestionArgs = {
  input: SaveGrammarTestQuestionInput;
};

export type MutationSaveLinkLocationCredentialArgs = {
  input: SaveLinkLocationCredentialInput;
};

export type MutationSaveOrderContentItemArgs = {
  input: SaveOrderContentItemInput;
};

export type MutationSavePartnerAnalyticCredentialArgs = {
  input: SavePartnerAnalyticCredentialInput;
};

export type MutationSaveProposedOrderTopicArgs = {
  input: SaveProposedOrderTopicInput;
};

export type MutationSaveTaskContentIdeationArgs = {
  input: SaveTaskContentIdeationInput;
};

export type MutationSaveTaskContentItemArgs = {
  input: SaveTaskContentItemInput;
};

export type MutationSaveTaskGbpItemArgs = {
  input: SaveTaskGbpItemInput;
};

export type MutationSaveTaskTitleMetaDescriptionsArgs = {
  input: SaveTaskTitleMetaDescriptionInput;
};

export type MutationSaveUserGrammarTestResponseArgs = {
  input: SaveUserGrammarTestResponseInput;
};

export type MutationSaveUserProfessionalArgs = {
  input: SaveUserProfessionalInput;
};

export type MutationSaveUserWorkInterestsArgs = {
  input: SaveUserWorkInterestsInput;
};

export type MutationSaveWritingSampleArgs = {
  input: SaveWritingSampleInput;
};

export type MutationSelectWritingSampleTopicArgs = {
  input: SelectWritingSampleTopicInput;
};

export type MutationSendEmailManagedSeoArgs = {
  input: SendEmailManagedSeoInput;
};

export type MutationSignupCsUserArgs = {
  input?: InputMaybe<SignupCsUserInput>;
};

export type MutationSubmitProposalReviewArgs = {
  input: SubmitProposalReviewInput;
};

export type MutationSyncUberallLocationArgs = {
  input: SyncUberallLocationInput;
};

export type MutationUpdateAmTaskArgs = {
  input: UpdateAmTaskInput;
};

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

export type MutationUpdateChecklistItemArgs = {
  input: UpdateChecklistItemInput;
};

export type MutationUpdateCreativesScaleUserArgs = {
  input?: InputMaybe<UpdateCreativeScaleUserInput>;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateCustomerBusinessListingSpecArgs = {
  input: UpdateCustomerBusinessListingSpecInput;
};

export type MutationUpdateCustomerCampaignArgs = {
  input: UpdateCustomerCampaignInput;
};

export type MutationUpdateCustomerConversationArgs = {
  input: UpdateCustomerConversationInput;
};

export type MutationUpdateCustomerKeywordArgs = {
  input: UpdateCustomerKeywordInput;
};

export type MutationUpdateCustomerKeywordProposalArgs = {
  input: UpdateCustomerKeywordProposalInput;
};

export type MutationUpdateCustomerNoteArgs = {
  input: UpdateCustomerNoteInput;
};

export type MutationUpdateCustomerProposalArgs = {
  input: UpdateCustomerProposalInput;
};

export type MutationUpdateCustomerStatusChangeArgs = {
  input: UpdateCustomerStatusChangeInput;
};

export type MutationUpdateCustomerUserArgs = {
  input: UpdateCustomerUserInput;
};

export type MutationUpdateFeedbackContentItemArgs = {
  input: UpdateFeedbackContentItemInput;
};

export type MutationUpdateFulfillmentRuleArgs = {
  input: UpdateFulfillmentRuleInput;
};

export type MutationUpdateLinkLocationArgs = {
  input: UpdateLinkLocationInput;
};

export type MutationUpdateLinkLocationCategoriesArgs = {
  input: UpdateLinkLocationCategoriesInput;
};

export type MutationUpdateLinkLocationMetricsArgs = {
  input: UpdateLinkLocationMetricsInput;
};

export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

export type MutationUpdateOrderContentItemArgs = {
  input: UpdateOrderContentItemInput;
};

export type MutationUpdateOrderContentStrategyArgs = {
  input: UpdateOrderContentStrategyInput;
};

export type MutationUpdateOrderExternalContentSpecArgs = {
  input: UpdateOrderExternalContentSpecInput;
};

export type MutationUpdateOrderGbpItemArgs = {
  input: UpdateOrderGbpItemInput;
};

export type MutationUpdateOrderTitleMetaDescriptionItemArgs = {
  input: UpdateOrderTitleMetaDescriptionItemInput;
};

export type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput;
};

export type MutationUpdatePartnerBillingContactArgs = {
  input: UpdatePartnerBillingContactInput;
};

export type MutationUpdatePartnerPackageArgs = {
  input: UpdatePartnerPackageInput;
};

export type MutationUpdatePartnerPackageItemArgs = {
  input: UpdatePartnerPackageItemInput;
};

export type MutationUpdatePartnerProductArgs = {
  input: UpdatePartnerProductInput;
};

export type MutationUpdatePartnerProductPriceArgs = {
  input: UpdatePartnerProductPriceInput;
};

export type MutationUpdatePartnerProductWholesalePriceArgs = {
  input: UpdatePartnerProductWholesalePriceInput;
};

export type MutationUpdatePartnerUserArgs = {
  input: UpdatePartnerUserInput;
};

export type MutationUpdateProposedCustomerKeywordArgs = {
  input: UpdateProposedCustomerKeywordInput;
};

export type MutationUpdatePublisherArgs = {
  input: UpdatePublisherInput;
};

export type MutationUpdatePublisherLinkLocationArgs = {
  input: UpdatePublisherLinkLocationInput;
};

export type MutationUpdatePublisherPaymentArgs = {
  input: UpdatePublisherPaymentInput;
};

export type MutationUpdateRcmWriterTasksArgs = {
  input: UpdateRcmWriterTasksInput;
};

export type MutationUpdateSitePostArgs = {
  input: UpdateSitePostInput;
};

export type MutationUpdateSitePostForTaskArgs = {
  input: UpdateSitePostForTaskInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserProfessionalArgs = {
  input: UpdateUserProfessionalInput;
};

export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput;
};

export type MutationUpdateVendorUserArgs = {
  input: UpdateVendorUserInput;
};

export type MutationUpdateWritingSampleTopicArgs = {
  input: UpdateWritingSampleTopicInput;
};

export type MutationVerifyGuestPostArgs = {
  input: VerifyGuestPostInput;
};

export type Note = {
  __typename?: "Note";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  user: User;
  userId: Scalars["Int"];
};

export type NoteConnection = {
  __typename?: "NoteConnection";
  nodes: Array<Note>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type OnboardingStatus = {
  __typename?: "OnboardingStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Order = {
  __typename?: "Order";
  activeOrderContentStrategy?: Maybe<OrderContentStrategy>;
  amount: Scalars["Float"];
  billingStatus: OrderBillingStatus;
  canDelete: Scalars["Boolean"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerBillingStatus: OrderBillingStatus;
  customerCampaign: CustomerCampaign;
  customerCampaignId: Scalars["Int"];
  customerId: Scalars["Int"];
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordId?: Maybe<Scalars["Int"]>;
  customerProposal?: Maybe<CustomerProposal>;
  customerReviewTask?: Maybe<Task>;
  customerSiteAudit?: Maybe<CustomerSiteAudit>;
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationId?: Maybe<Scalars["Int"]>;
  orderContentItems: Array<OrderContentItem>;
  orderContentStrategies: Array<OrderContentStrategy>;
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
  orderGbpItem?: Maybe<OrderGbpItem>;
  orderIssues: Array<OrderIssue>;
  orderTitleMetaDescriptionItem?: Maybe<OrderTitleMetaDescriptionItem>;
  partnerInvoiceCharges: Array<PartnerInvoiceCharge>;
  partnerProduct: PartnerProduct;
  proposalTask?: Maybe<Task>;
  publisherPayment?: Maybe<PublisherPayment>;
  publisherPaymentStatus: OrderBillingStatus;
  quantity: Scalars["Int"];
  resultUrl?: Maybe<Scalars["String"]>;
  resultUrlDomainAuthority?: Maybe<Scalars["Int"]>;
  resultUrlTraffic?: Maybe<Scalars["Int"]>;
  startDate: Scalars["DateTime"];
  status: OrderStatus;
  targetLinkLocation?: Maybe<LinkLocation>;
  targetLinkLocationId?: Maybe<Scalars["Int"]>;
  targetResultUrl?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  totalRetailAmount: Scalars["Float"];
  totalWholesaleAmount: Scalars["Float"];
  trackingId?: Maybe<Scalars["String"]>;
  user: User;
  userId: Scalars["Int"];
  workflow?: Maybe<Workflow>;
  workflowInternalStatus?: Maybe<Scalars["String"]>;
};

export type OrderOrderContentItemsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type OrderPublisherPaymentArgs = {
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type OrderBillingStatus = {
  __typename?: "OrderBillingStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrderConnection = {
  __typename?: "OrderConnection";
  nodes: Array<Order>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type OrderContentItem = {
  __typename?: "OrderContentItem";
  contentItem: ContentItem;
  contentItemDelivery: ContentItemDelivery;
  contentItemId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isPostPublished: Scalars["Boolean"];
  lastVerifiedPostAt?: Maybe<Scalars["DateTime"]>;
  order: Order;
  orderId: Scalars["Int"];
  publicId?: Maybe<Scalars["String"]>;
};

export type OrderContentItemConnection = {
  __typename?: "OrderContentItemConnection";
  nodes: Array<OrderContentItem>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum OrderContentStrategiesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  OrderStartDateAsc = "ORDER_START_DATE_ASC",
  OrderStartDateDesc = "ORDER_START_DATE_DESC",
}

export type OrderContentStrategy = {
  __typename?: "OrderContentStrategy";
  clientProvidedInstructions?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordId?: Maybe<Scalars["Int"]>;
  customerProposal?: Maybe<CustomerProposal>;
  customerProposalId?: Maybe<Scalars["Int"]>;
  focusPoints?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isComplete: Scalars["Boolean"];
  isProposed: Scalars["Boolean"];
  linkTo?: Maybe<Scalars["String"]>;
  order?: Maybe<Order>;
  orderContentStrategyReviews: Array<OrderContentStrategyReview>;
  orderHaloKeywords: Array<OrderHaloKeyword>;
  orderId: Scalars["Int"];
  pageType?: Maybe<Scalars["String"]>;
  purposeOfContent?: Maybe<Scalars["String"]>;
  sampleContent?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  voice?: Maybe<Scalars["String"]>;
  webPage?: Maybe<Scalars["String"]>;
};

export type OrderContentStrategyReview = {
  __typename?: "OrderContentStrategyReview";
  comments?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdByUser: User;
  createdByUserId: Scalars["Int"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  orderContentStrategyId: Scalars["Int"];
  orderId: Scalars["Int"];
  resolvedAt?: Maybe<Scalars["DateTime"]>;
};

export type OrderExternalContentSpec = {
  __typename?: "OrderExternalContentSpec";
  addedByUserId: Scalars["Int"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  cost: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  domainAuthority?: Maybe<Scalars["Int"]>;
  externalOrderId: Scalars["String"];
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  submittedAt: Scalars["DateTime"];
  trustFlow?: Maybe<Scalars["Int"]>;
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type OrderGbpItem = {
  __typename?: "OrderGbpItem";
  createdAt: Scalars["DateTime"];
  gbpItem: GbpItem;
  gbpItemId: Scalars["Int"];
  id: Scalars["Int"];
  isProfilePublished: Scalars["Boolean"];
  order: Order;
  orderId: Scalars["Int"];
};

export type OrderHaloKeyword = {
  __typename?: "OrderHaloKeyword";
  createdAt: Scalars["DateTime"];
  customerKeyword: CustomerKeyword;
  customerKeywordId: Scalars["Int"];
  customerProposal?: Maybe<CustomerProposal>;
  customerProposalId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  isProposed: Scalars["Boolean"];
  order: Order;
  orderId: Scalars["Int"];
};

export type OrderInput = {
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  keywordId?: InputMaybe<Scalars["Int"]>;
  partnerProductId: Scalars["Int"];
  startDate?: InputMaybe<Scalars["Date"]>;
  targetResultUrl?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  trackingId?: InputMaybe<Scalars["String"]>;
};

export type OrderIssue = {
  __typename?: "OrderIssue";
  message: Scalars["String"];
  title: Scalars["String"];
};

export type OrderStatus = {
  __typename?: "OrderStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrderTitleMetaDescriptionItem = {
  __typename?: "OrderTitleMetaDescriptionItem";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isPublished: Scalars["Boolean"];
  orderId: Scalars["Int"];
  titleMetaDescriptionItem: TitleMetaDescriptionItem;
  titleMetaDescriptionItemId: Scalars["Int"];
};

export enum OrdersSort {
  CompletedAtAsc = "COMPLETED_AT_ASC",
  CompletedAtDesc = "COMPLETED_AT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
}

export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
};

export type Partner = {
  __typename?: "Partner";
  autopayPartnerPaymentMethod?: Maybe<PartnerPaymentMethod>;
  autopayPartnerPaymentMethodId?: Maybe<Scalars["Int"]>;
  billableOrders: OrderConnection;
  billingEmail?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  emailLogoFile?: Maybe<File>;
  enableAutopayInformation?: Maybe<EnableAutopayInformation>;
  id: Scalars["Int"];
  isAutopayEnabled: Scalars["Boolean"];
  isBillingEnforced: Scalars["Boolean"];
  isUpfrontPaymentRequired: Scalars["Boolean"];
  name: Scalars["String"];
  partnerAnalyticCredential?: Maybe<PartnerAnalyticCredential>;
  partnerAvailableProducts: Array<PartnerAvailableProduct>;
  partnerBillingContacts: PartnerBillingContactConnection;
  partnerInvoices: PartnerInvoiceConnection;
  partnerPackages: PartnerPackageConnection;
  partnerPaymentMethods: PartnerPaymentMethodConnection;
  partnerProducts: PartnerProductConnection;
  partnerTaskTypeVendors: Array<PartnerTaskTypeVendor>;
  partnerUsers: PartnerUserConnection;
  portal?: Maybe<Scalars["String"]>;
  reportingLogoFile?: Maybe<File>;
  stripeAccountId?: Maybe<Scalars["String"]>;
};

export type PartnerBillableOrdersArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
};

export type PartnerPartnerBillingContactsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerPaymentMethodsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerProductsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type PartnerPartnerUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type PartnerAnalyticCredential = {
  __typename?: "PartnerAnalyticCredential";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  password?: Maybe<Scalars["String"]>;
  passwordHash: Scalars["String"];
  userName: Scalars["String"];
};

export type PartnerAvailableProduct = {
  __typename?: "PartnerAvailableProduct";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerProductWholesalePrices: PartnerProductWholesalePriceConnection;
  product: Product;
  productId: Scalars["String"];
  wholesalePrice: Scalars["Float"];
};

export type PartnerAvailableProductPartnerProductWholesalePricesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerProductWholesalePriceSort>;
};

export type PartnerBillingContact = {
  __typename?: "PartnerBillingContact";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type PartnerBillingContactConnection = {
  __typename?: "PartnerBillingContactConnection";
  nodes: Array<PartnerBillingContact>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerConnection = {
  __typename?: "PartnerConnection";
  nodes: Array<Partner>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoice = {
  __typename?: "PartnerInvoice";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  invoiceDate: Scalars["DateTime"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerInvoiceCharges: PartnerInvoiceChargeConnection;
  partnerInvoicePayments: PartnerInvoicePaymentConnection;
  partnerInvoiceRefunds: PartnerInvoiceRefundConnection;
  status?: Maybe<PartnerInvoiceStatus>;
  statusId: Scalars["String"];
  totalChargesAmount: Scalars["Float"];
  totalDueAmount: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
  totalRefundsAmount: Scalars["Float"];
};

export type PartnerInvoiceCharge = {
  __typename?: "PartnerInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["Int"]>;
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerInvoiceId: Scalars["Int"];
  status?: Maybe<PartnerInvoiceChargeStatus>;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type PartnerInvoiceChargeConnection = {
  __typename?: "PartnerInvoiceChargeConnection";
  nodes: Array<PartnerInvoiceCharge>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoiceChargeStatus = {
  __typename?: "PartnerInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerInvoiceConnection = {
  __typename?: "PartnerInvoiceConnection";
  nodes: Array<PartnerInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoicePayment = {
  __typename?: "PartnerInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerInvoiceId: Scalars["Int"];
  partnerPaymentMethod: PartnerPaymentMethod;
  partnerPaymentMethodId: Scalars["Int"];
  paymentMethodDescription: Scalars["String"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status?: Maybe<PartnerInvoicePaymentStatus>;
  statusId: Scalars["String"];
};

export type PartnerInvoicePaymentConnection = {
  __typename?: "PartnerInvoicePaymentConnection";
  nodes: Array<PartnerInvoicePayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoicePaymentStatus = {
  __typename?: "PartnerInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerInvoiceRefund = {
  __typename?: "PartnerInvoiceRefund";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerInvoice: PartnerInvoice;
  partnerInvoiceId: Scalars["Int"];
  partnerInvoicePayment: PartnerInvoicePayment;
  partnerInvoicePaymentId: Scalars["Int"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
  status?: Maybe<PartnerInvoicePaymentStatus>;
  statusId: Scalars["String"];
};

export type PartnerInvoiceRefundConnection = {
  __typename?: "PartnerInvoiceRefundConnection";
  nodes: Array<PartnerInvoiceRefund>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoiceRefundStatus = {
  __typename?: "PartnerInvoiceRefundStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum PartnerInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PartnerInvoiceStatus = {
  __typename?: "PartnerInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerPackage = {
  __typename?: "PartnerPackage";
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  monthlySpend: Scalars["Float"];
  months: Scalars["Int"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  partnerPackageItemCount: Scalars["Int"];
  partnerPackageItems: Array<PartnerPackageItem>;
};

export type PartnerPackageConnection = {
  __typename?: "PartnerPackageConnection";
  nodes: Array<PartnerPackage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerPackageItem = {
  __typename?: "PartnerPackageItem";
  id: Scalars["Int"];
  isRecurring: Scalars["Boolean"];
  monthIndex: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerPackage: PartnerPackage;
  partnerPackageId: Scalars["Int"];
  partnerProduct: PartnerProduct;
  partnerProductId: Scalars["Int"];
  quantity: Scalars["Int"];
  totalPrice: Scalars["Float"];
  unitPrice: Scalars["Float"];
};

export enum PartnerPackageSort {
  MonthlySpendAsc = "MONTHLY_SPEND_ASC",
  MonthlySpendDesc = "MONTHLY_SPEND_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PartnerPaymentMethod = {
  __typename?: "PartnerPaymentMethod";
  createdAt: Scalars["DateTime"];
  externalId: Scalars["String"];
  externalProvider: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerPaymentMethodCard?: Maybe<PartnerPaymentMethodCard>;
  type: Scalars["String"];
};

export type PartnerPaymentMethodCard = {
  __typename?: "PartnerPaymentMethodCard";
  brand: Scalars["String"];
  brandDisplayName: Scalars["String"];
  expirationMonth: Scalars["Int"];
  expirationYear: Scalars["Int"];
  last4Digits: Scalars["String"];
};

export type PartnerPaymentMethodConnection = {
  __typename?: "PartnerPaymentMethodConnection";
  nodes: Array<PartnerPaymentMethod>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerProduct = {
  __typename?: "PartnerProduct";
  activeAt: Scalars["DateTime"];
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  canDelete: Scalars["Boolean"];
  checklistDefinition?: Maybe<ChecklistDefinition>;
  checklistDefinitionId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currentPartnerProductPrice?: Maybe<PartnerProductPrice>;
  currentPartnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
  id: Scalars["Int"];
  inactiveAt?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerProductPrices: PartnerProductPriceConnection;
  partnerProductWholesalePrices: PartnerProductWholesalePriceConnection;
  product: Product;
  productId: Scalars["String"];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars["Int"]>;
  wholesalePrice?: Maybe<Scalars["Float"]>;
};

export type PartnerProductPartnerProductPricesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerProductPriceSort>;
  startsAt?: InputMaybe<DateQuery>;
};

export type PartnerProductPartnerProductWholesalePricesArgs = {
  effectiveAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerProductConnection = {
  __typename?: "PartnerProductConnection";
  nodes: Array<PartnerProduct>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerProductPrice = {
  __typename?: "PartnerProductPrice";
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  partnerProduct: PartnerProduct;
  partnerProductId: Scalars["Int"];
  price: Scalars["Float"];
  startsAt: Scalars["DateTime"];
};

export type PartnerProductPriceConnection = {
  __typename?: "PartnerProductPriceConnection";
  nodes: Array<PartnerProductPrice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerProductPriceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartsAtAsc = "STARTS_AT_ASC",
  StartsAtDesc = "STARTS_AT_DESC",
}

export type PartnerProductWholesalePrice = {
  __typename?: "PartnerProductWholesalePrice";
  canDelete: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesaleAmount: Scalars["Float"];
};

export type PartnerProductWholesalePriceConnection = {
  __typename?: "PartnerProductWholesalePriceConnection";
  nodes: Array<PartnerProductWholesalePrice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerProductWholesalePriceSort {
  EffectiveAtAsc = "EFFECTIVE_AT_ASC",
  EffectiveAtDesc = "EFFECTIVE_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PartnerTaskTypeVendor = {
  __typename?: "PartnerTaskTypeVendor";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  taskType: TaskType;
  taskTypeId: Scalars["String"];
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type PartnerUser = {
  __typename?: "PartnerUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  isAdmin: Scalars["Boolean"];
  partner: Partner;
  partnerId: Scalars["Int"];
  user: User;
  userId: Scalars["Int"];
};

export type PartnerUserConnection = {
  __typename?: "PartnerUserConnection";
  nodes: Array<PartnerUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnersSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PayPartnerInvoiceInput = {
  partnerInvoiceId: Scalars["Int"];
  partnerPaymentMethodId: Scalars["Int"];
};

export type PayPartnerInvoiceResponse = {
  __typename?: "PayPartnerInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type PaymentBatch = {
  __typename?: "PaymentBatch";
  createdAt: Scalars["DateTime"];
  creativesScaleUserPayments?: Maybe<Array<CreativesScaleUserPayment>>;
  externalFundingTransferId?: Maybe<Scalars["String"]>;
  feesTotalAmount: Scalars["Float"];
  fundingApprovedAt?: Maybe<Scalars["DateTime"]>;
  fundingApprovedByUserId?: Maybe<Scalars["Int"]>;
  fundingStatus: PaymentBatchFundingStatus;
  fundingStatusId: Scalars["String"];
  id: Scalars["Int"];
  paymentTotalAmount: Scalars["Float"];
  publisherPayments?: Maybe<Array<PublisherPayment>>;
  status: PaymentBatchStatus;
  statusId: Scalars["String"];
  totalAmount: Scalars["Float"];
};

export type PaymentBatchFundingStatus = {
  __typename?: "PaymentBatchFundingStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PaymentBatchStatus = {
  __typename?: "PaymentBatchStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum PaymentsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PostalAddress = {
  __typename?: "PostalAddress";
  addressLines?: Maybe<Array<Scalars["String"]>>;
  administrativeArea?: Maybe<Scalars["String"]>;
  languageCode?: Maybe<Scalars["String"]>;
  locality?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  regionCode?: Maybe<Scalars["String"]>;
  revision?: Maybe<Scalars["Int"]>;
  sortingCode?: Maybe<Scalars["String"]>;
  sublocality?: Maybe<Scalars["String"]>;
};

export type Product = {
  __typename?: "Product";
  campaignCategory: CampaignCategory;
  campaignCategoryId: Scalars["String"];
  defaultWholesalePrice: Scalars["Int"];
  deprecatedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isChecklistRequired: Scalars["Boolean"];
  isKeywordRequired: Scalars["Boolean"];
  isSystemManaged: Scalars["Boolean"];
  isSystemOrdered: Scalars["Boolean"];
  isTopicRequired: Scalars["Boolean"];
  isVendorRequired: Scalars["Boolean"];
  isWebsiteRequired: Scalars["Boolean"];
  maxOrderQuantity: Scalars["Int"];
  maxWordCount?: Maybe<Scalars["Int"]>;
  minDA?: Maybe<Scalars["Int"]>;
  minSV?: Maybe<Scalars["Int"]>;
  minWordCount?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type ProductConnection = {
  __typename?: "ProductConnection";
  nodes: Array<Product>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ProductTaskType = {
  __typename?: "ProductTaskType";
  id: Scalars["String"];
  product: Product;
  productId: Scalars["String"];
  taskType: TaskType;
  taskTypeId: Scalars["String"];
};

export type ProductTaskTypeConnection = {
  __typename?: "ProductTaskTypeConnection";
  nodes: Array<ProductTaskType>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ProductTaskTypePair = {
  productId: Scalars["String"];
  taskTypeId: Scalars["String"];
};

export enum ProductsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ProposedCustomerKeyword = {
  __typename?: "ProposedCustomerKeyword";
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerKeywordProposalId: Scalars["Int"];
  id: Scalars["Int"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
};

export enum ProposedCustomerKeywordSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ProposedOrderTopic = {
  __typename?: "ProposedOrderTopic";
  customerKeyword: CustomerKeyword;
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  rejectionComment?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
};

export type Publisher = {
  __typename?: "Publisher";
  contactEmail?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars["Int"]>;
  emailMessages?: Maybe<EmailMessageConnection>;
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isAutoPayEnabled?: Maybe<Scalars["Boolean"]>;
  isInvoiceRequired?: Maybe<Scalars["Boolean"]>;
  isSlowPartner?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  paidAmount: Scalars["Float"];
  paymentMethod?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  publishedOrders: OrderConnection;
  publisherLinkLocations: PublisherLinkLocationConnection;
  publisherUsers: PublisherUserConnection;
  requestedOrders: OrderConnection;
};

export type PublisherEmailMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PublisherPaidAmountArgs = {
  daysAgo?: InputMaybe<Scalars["Int"]>;
};

export type PublisherPublishedOrdersArgs = {
  daysAgo?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
};

export type PublisherPublisherLinkLocationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type PublisherPublisherUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherUsersSort>;
};

export type PublisherRequestedOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
};

export type PublisherConnection = {
  __typename?: "PublisherConnection";
  nodes: Array<Publisher>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PublisherLinkLocation = {
  __typename?: "PublisherLinkLocation";
  createdAt: Scalars["Date"];
  id: Scalars["Int"];
  isRCMPublishing?: Maybe<Scalars["Boolean"]>;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationId: Scalars["Int"];
  publisher?: Maybe<Publisher>;
  publisherId: Scalars["Int"];
};

export type PublisherLinkLocationConnection = {
  __typename?: "PublisherLinkLocationConnection";
  nodes: Array<PublisherLinkLocation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PublisherLinkLocationSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PublisherPayment = {
  __typename?: "PublisherPayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  orderId?: Maybe<Scalars["Int"]>;
  paymentBatchId?: Maybe<Scalars["Int"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  publisher?: Maybe<Publisher>;
  publisherId?: Maybe<Scalars["Int"]>;
  status?: Maybe<PublisherPaymentStatus>;
};

export type PublisherPaymentConnection = {
  __typename?: "PublisherPaymentConnection";
  nodes: Array<PublisherPayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PublisherPaymentStatus = {
  __typename?: "PublisherPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PublisherUser = {
  __typename?: "PublisherUser";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isAdmin: Scalars["Boolean"];
  publisher: Publisher;
  publisherId: Scalars["Int"];
  user: User;
  userId: Scalars["Int"];
};

export type PublisherUserConnection = {
  __typename?: "PublisherUserConnection";
  nodes: Array<PublisherUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PublisherUsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export enum PublishersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PurchaseDomainInput = {
  domainId: Scalars["String"];
};

export type PurchaseDomainResponse = {
  __typename?: "PurchaseDomainResponse";
  domain: Domain;
};

export type Query = {
  __typename?: "Query";
  adminReports: AdminReports;
  amTask: AmTask;
  amTasks: AmTaskConnection;
  blockDomains: BlockDomainConnection;
  campaignCategories: CampaignCategoryConnection;
  categories: CategoryConnection;
  category?: Maybe<Category>;
  checklistDefinition?: Maybe<ChecklistDefinition>;
  checklistDefinitions: ChecklistDefinitionConnection;
  creativesScaleConfiguration: CreativesScaleConfiguration;
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  creativesScaleUsers: CreativesScaleUserConnection;
  customer?: Maybe<Customer>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerCampaignInvoice?: Maybe<CustomerCampaignInvoice>;
  customerCampaignInvoices: CustomerCampaignInvoiceConnection;
  customerConversation?: Maybe<CustomerConversation>;
  customerConversations: CustomerConversationConnection;
  customerInvoice?: Maybe<CustomerInvoice>;
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordRankings: CustomerKeywordRankingConnection;
  customerKeywords: CustomerKeywordConnection;
  customerUser?: Maybe<CustomerUser>;
  customers: CustomerConnection;
  domain?: Maybe<Domain>;
  domainStatuses: Array<DomainStatus>;
  domains: DomainConnection;
  emailMessage?: Maybe<EmailMessage>;
  fulfillmentRules: FulfillmentRuleConnection;
  getDomainMetrics: DomainMetricsRespose;
  getGoogleAccounts: GoogleAccountsResponse;
  getGoogleLocations: GoogleLocationsResponse;
  getGoogleReviews: GoogleReviewRespose;
  getKeywordsKeywords: KeywordsSuggestionRespose;
  getKeywordsSite: KeywordsSuggestionRespose;
  getTopicKeyword: TopicKeywordResponse;
  grammarTestQuestion?: Maybe<GrammarTestQuestion>;
  grammarTestQuestions: GrammarTestQuestionConnection;
  keywordLocations: LocationResponse;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationStatuses: Array<LinkLocationStatus>;
  linkLocationTypes: Array<LinkLocationType>;
  linkLocations: LinkLocationConnection;
  order?: Maybe<Order>;
  orderContentItems: OrderContentItemConnection;
  orderStatuses: Array<OrderStatus>;
  orders: OrderConnection;
  partner?: Maybe<Partner>;
  partnerAvailableProduct?: Maybe<PartnerAvailableProduct>;
  partnerInvoice?: Maybe<PartnerInvoice>;
  partnerInvoices: PartnerInvoiceConnection;
  partnerPackage?: Maybe<PartnerPackage>;
  partnerPackages: PartnerPackageConnection;
  partnerProduct?: Maybe<PartnerProduct>;
  partnerProducts: PartnerProductConnection;
  partners: PartnerConnection;
  paymentBatch?: Maybe<PaymentBatch>;
  productTaskTypes: ProductTaskTypeConnection;
  products: ProductConnection;
  publisher?: Maybe<Publisher>;
  publisherLinkLocations: PublisherLinkLocationConnection;
  publisherPayments: PublisherPaymentConnection;
  publishers: PublisherConnection;
  site?: Maybe<Site>;
  siteDeployStatuses: Array<SiteDeployStatus>;
  sitePost?: Maybe<SitePost>;
  sitePostStatuses: Array<SitePostStatus>;
  sitePosts: SitePostConnection;
  sites: SiteConnection;
  task?: Maybe<Task>;
  taskStatuses: Array<TaskStatus>;
  taskTypes: TaskTypeConnection;
  tasks: TaskConnection;
  uberallCategories?: Maybe<Array<UberallCategory>>;
  uberallListingDetails: UberallListingDetailsResponse;
  user?: Maybe<User>;
  userTaskReport?: Maybe<UserTaskReport>;
  userWritingSample?: Maybe<UserWritingSample>;
  userWritingSamples: UserWritingSampleConnection;
  users: UserConnection;
  vendor?: Maybe<Vendor>;
  vendors: VendorConnection;
  version?: Maybe<Scalars["String"]>;
  viewer: Viewer;
  workflow?: Maybe<Workflow>;
  workflows: WorkflowConnection;
  writingSampleTopics: WritingSampleTopicConnection;
};

export type QueryAmTaskArgs = {
  id: Scalars["Int"];
};

export type QueryAmTasksArgs = {
  completedAt?: InputMaybe<DateQuery>;
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryBlockDomainsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCampaignCategoriesArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CampaignCategoriesSort>;
};

export type QueryCategoriesArgs = {
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CategoriesSort>;
};

export type QueryCategoryArgs = {
  id: Scalars["Int"];
};

export type QueryChecklistDefinitionArgs = {
  id: Scalars["Int"];
};

export type QueryChecklistDefinitionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryCreativesScaleUserArgs = {
  creativesScaleUserId: Scalars["Int"];
};

export type QueryCreativesScaleUsersArgs = {
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onboardingStatusIds?: InputMaybe<Array<Scalars["String"]>>;
  permissionTaskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryCustomerArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerBusinessListingSpecArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerCampaignInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerCampaignInvoicesArgs = {
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerCampaignInvoiceSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCustomerConversationArgs = {
  conversationId: Scalars["String"];
};

export type QueryCustomerConversationsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerConversationsSort>;
  wasStartedByCustomer?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryCustomerInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerKeywordArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerKeywordRankingsArgs = {
  createdAt?: InputMaybe<DateQuery>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchEngine?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerKeywordRankingSort>;
};

export type QueryCustomerKeywordsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  locationNotNull?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersKeywordSort>;
};

export type QueryCustomerUserArgs = {
  id: Scalars["Int"];
};

export type QueryCustomersArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]>>;
  partnerPortal?: InputMaybe<Scalars["String"]>;
  rcmAccountManagerUserId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryDomainArgs = {
  id: Scalars["String"];
};

export type QueryDomainsArgs = {
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<DomainsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryEmailMessageArgs = {
  id: Scalars["Int"];
};

export type QueryFulfillmentRulesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetDomainMetricsArgs = {
  domain: Scalars["String"];
};

export type QueryGetGoogleAccountsArgs = {
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleLocationsArgs = {
  account: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleReviewsArgs = {
  account: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  pageToken?: InputMaybe<Scalars["String"]>;
};

export type QueryGetKeywordsKeywordsArgs = {
  keywords: Array<Scalars["String"]>;
};

export type QueryGetKeywordsSiteArgs = {
  domain: Scalars["String"];
};

export type QueryGetTopicKeywordArgs = {
  keyword: Scalars["String"];
};

export type QueryGrammarTestQuestionArgs = {
  id: Scalars["Int"];
};

export type QueryGrammarTestQuestionsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryKeywordLocationsArgs = {
  country: Scalars["String"];
  search: Scalars["String"];
};

export type QueryLinkLocationArgs = {
  id: Scalars["Int"];
};

export type QueryLinkLocationsArgs = {
  associatedToPublisher?: InputMaybe<Scalars["Boolean"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  excludeCustomerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  minDa?: InputMaybe<Scalars["Int"]>;
  minTraffic?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  secondSort?: InputMaybe<LinkLocationsSort>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryOrderArgs = {
  id: Scalars["Int"];
};

export type QueryOrderContentItemsArgs = {
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
  lastVerifiedPostAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
};

export type QueryOrdersArgs = {
  completedAt?: InputMaybe<DateQuery>;
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<OrdersSort>;
  startDate?: InputMaybe<DateQuery>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  treatReportingSmartsResultUrlAsNull?: InputMaybe<Scalars["Boolean"]>;
  workflowInternalStatusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPartnerArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerAvailableProductArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]>>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PartnerInvoiceSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPartnerPackageArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerPackagesArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerPackageSort>;
};

export type QueryPartnerProductArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerProductsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryPartnersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  portal?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PartnersSort>;
};

export type QueryPaymentBatchArgs = {
  id: Scalars["Int"];
};

export type QueryProductTaskTypesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryProductsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ProductsSort>;
};

export type QueryPublisherArgs = {
  id: Scalars["Int"];
};

export type QueryPublisherLinkLocationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPublisherPaymentsArgs = {
  checkPublisherAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderIds?: InputMaybe<Array<Scalars["Int"]>>;
  paymentBatchId?: InputMaybe<Scalars["Int"]>;
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  statusLastUpdatedAt?: InputMaybe<DateQuery>;
};

export type QueryPublishersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublishersSort>;
};

export type QuerySiteArgs = {
  siteId: Scalars["String"];
};

export type QuerySitePostArgs = {
  id: Scalars["Int"];
};

export type QuerySitePostsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  siteIds?: InputMaybe<Array<Scalars["String"]>>;
  sort?: InputMaybe<SitePostsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuerySitesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SitesSort>;
};

export type QueryTaskArgs = {
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
};

export type QueryTaskTypesArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryTasksArgs = {
  assignedAt?: InputMaybe<DateQuery>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]>>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  completedAt?: InputMaybe<DateQuery>;
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  excludedVendorIds?: InputMaybe<Array<Scalars["Int"]>>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  isFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  notTaskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  offset?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  productTaskTypes?: InputMaybe<Array<InputMaybe<ProductTaskTypePair>>>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]>>;
  workflowStatuses?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryUberallCategoriesArgs = {
  categories?: InputMaybe<Scalars["String"]>;
  language: Scalars["String"];
  max?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  q?: InputMaybe<Scalars["String"]>;
  roots?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryUberallListingDetailsArgs = {
  locationId: Scalars["Int"];
};

export type QueryUserArgs = {
  id: Scalars["Int"];
};

export type QueryUserTaskReportArgs = {
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  userId: Scalars["Int"];
};

export type QueryUserWritingSampleArgs = {
  userWritingSampleId: Scalars["Int"];
};

export type QueryUserWritingSamplesArgs = {
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  reviewedAt?: InputMaybe<DateQuery>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<UserWritingSamplesSort>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  isAdminOrSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<UsersSort>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type QueryVendorArgs = {
  id: Scalars["Int"];
};

export type QueryVendorsArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  showExternalVendors?: InputMaybe<Scalars["Boolean"]>;
  showSystemVendors?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<VendorsSort>;
};

export type QueryWorkflowArgs = {
  workflowId: Scalars["Int"];
};

export type QueryWorkflowsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryWritingSampleTopicsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type ReCreateGuestOutreachTaskInput = {
  orderId: Scalars["Int"];
};

export type ReCreateGuestOutreachTaskResponse = {
  __typename?: "ReCreateGuestOutreachTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type RecordCustomerCampaignInvoicePaymentInput = {
  amount: Scalars["Float"];
  customerCampaignInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordCustomerCampaignInvoicePaymentResponse = {
  __typename?: "RecordCustomerCampaignInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RecordCustomerInvoicePaymentInput = {
  amount: Scalars["Float"];
  customerInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordCustomerInvoicePaymentResponse = {
  __typename?: "RecordCustomerInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RecordPartnerInvoicePaymentInput = {
  amount: Scalars["Float"];
  partnerInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordPartnerInvoicePaymentResponse = {
  __typename?: "RecordPartnerInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RefreshDomainAvailabilityInput = {
  domainId: Scalars["String"];
};

export type RefreshDomainAvailabilityResponse = {
  __typename?: "RefreshDomainAvailabilityResponse";
  ok: Scalars["Boolean"];
};

export type RejectUserWritingSampleInput = {
  comments: Scalars["String"];
  userWritingSampleId: Scalars["Int"];
};

export type RejectUserWritingSampleResponse = {
  __typename?: "RejectUserWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type RequestPasswordResetInput = {
  email: Scalars["String"];
  origin?: InputMaybe<Scalars["String"]>;
};

export type RequestPasswordResetResponse = {
  __typename?: "RequestPasswordResetResponse";
  wasRequested: Scalars["Boolean"];
};

export type ResendConfirmationEmailInput = {
  creativesScaleUserId?: InputMaybe<Scalars["Int"]>;
  email?: InputMaybe<Scalars["String"]>;
};

export type ResendConfirmationEmailResponse = {
  __typename?: "ResendConfirmationEmailResponse";
  ok: Scalars["Boolean"];
};

export type ResetPasswordInput = {
  id: Scalars["String"];
  password: Scalars["String"];
};

export type ResetPasswordResponse = {
  __typename?: "ResetPasswordResponse";
  wasReset: Scalars["Boolean"];
};

export type ResolveOrderContentStrategyReviewInput = {
  orderContentStrategyReviewId: Scalars["Int"];
};

export type ResolveOrderContentStrategyReviewResponse = {
  __typename?: "ResolveOrderContentStrategyReviewResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentStrategyReview?: Maybe<OrderContentStrategyReview>;
};

export type ResponseError = {
  __typename?: "ResponseError";
  inputFieldErrors: Array<InputFieldError>;
  message: Scalars["String"];
};

export type ReviewReply = {
  __typename?: "ReviewReply";
  comment?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
};

export type Reviewer = {
  __typename?: "Reviewer";
  displayName?: Maybe<Scalars["String"]>;
  isAnonymous?: Maybe<Scalars["Boolean"]>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
};

export type SaveCustomerBillingConfigInput = {
  customerId: Scalars["Int"];
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveCustomerBillingConfigResponse = {
  __typename?: "SaveCustomerBillingConfigResponse";
  customerBillingConfig: CustomerBillingConfig;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerBusinessListingSpecResponse = {
  __typename?: "SaveCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec: CustomerBusinessListingSpec;
};

export type SaveCustomerHoursOfOperationItemsInput = {
  itemsToCreate: Array<CreateCustomerHoursOfOperationItemInput>;
  itemsToDelete: Array<Scalars["Int"]>;
  itemsToUpdate: Array<UpdateCustomerHoursOfOperationItemInput>;
};

export type SaveCustomerHoursOfOperationItemsResponse = {
  __typename?: "SaveCustomerHoursOfOperationItemsResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveCustomerPremiumBusinessListingSpecInput = {
  customerId: Scalars["Int"];
  hotelAmenities?: InputMaybe<Array<Scalars["String"]>>;
  hotelBathroom?: InputMaybe<Scalars["String"]>;
  hotelFrontDesk?: InputMaybe<Scalars["String"]>;
  hotelHousekeeping?: InputMaybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: InputMaybe<Scalars["String"]>;
  hotelMinimumStay?: InputMaybe<Scalars["String"]>;
  hotelOnSiteStaff?: InputMaybe<Scalars["Boolean"]>;
  hotelSecurity?: InputMaybe<Scalars["String"]>;
  isAlwaysOpen?: InputMaybe<Scalars["Boolean"]>;
  isBlackOwned?: InputMaybe<Scalars["Boolean"]>;
  isWomenOwned?: InputMaybe<Scalars["Boolean"]>;
  priceRangeLevel?: InputMaybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: InputMaybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: InputMaybe<Scalars["String"]>;
  yearsInBusiness?: InputMaybe<Scalars["Int"]>;
};

export type SaveCustomerPremiumBusinessListingSpecResponse = {
  __typename?: "SaveCustomerPremiumBusinessListingSpecResponse";
  customerPremiumBusinessListingSpec: CustomerPremiumBusinessListingSpec;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerQuestionnaireInput = {
  businessDescription?: InputMaybe<Scalars["String"]>;
  callToActionUrls?: InputMaybe<Scalars["String"]>;
  competitorWebsites?: InputMaybe<Scalars["String"]>;
  contentPreference?: InputMaybe<Scalars["String"]>;
  contentPublishingPermissions?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  customerMarketSize?: InputMaybe<Scalars["String"]>;
  isAddImage?: InputMaybe<Scalars["Boolean"]>;
  isRCMPublishing?: InputMaybe<Scalars["Boolean"]>;
  preferredVoice?: InputMaybe<Scalars["String"]>;
  productsOffered?: InputMaybe<Scalars["String"]>;
  purposeOfContent?: InputMaybe<Scalars["String"]>;
  serviceArea?: InputMaybe<Scalars["String"]>;
  targetArea?: InputMaybe<Scalars["String"]>;
  targetAudience?: InputMaybe<Scalars["String"]>;
  targetInternalLinks?: InputMaybe<Scalars["String"]>;
  targetKeywords?: InputMaybe<Scalars["String"]>;
  websiteCms?: InputMaybe<Scalars["String"]>;
  websiteHosting?: InputMaybe<Scalars["String"]>;
  whoCanChangeWebsite?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerQuestionnaireResponse = {
  __typename?: "SaveCustomerQuestionnaireResponse";
  customerQuestionnaire: CustomerQuestionnaire;
};

export type SaveCustomerSiteCredentialInput = {
  adminUrl?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerSiteCredentialResponse = {
  __typename?: "SaveCustomerSiteCredentialResponse";
  customerSiteCredential: CustomerSiteCredential;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveGrammarTestQuestionInput = {
  answers?: InputMaybe<Array<GrammarTestQuestionAnswerInput>>;
  grammarTestQuestionId?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  prompt?: InputMaybe<Scalars["String"]>;
};

export type SaveGrammarTestQuestionResponse = {
  __typename?: "SaveGrammarTestQuestionResponse";
  error?: Maybe<ResponseError>;
  grammarTestQuestion?: Maybe<GrammarTestQuestion>;
  ok: Scalars["Boolean"];
};

export type SaveLinkLocationCredentialInput = {
  adminUrl: Scalars["String"];
  instructions?: InputMaybe<Scalars["String"]>;
  linkLocationId: Scalars["Int"];
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type SaveLinkLocationCredentialResponse = {
  __typename?: "SaveLinkLocationCredentialResponse";
  error?: Maybe<ResponseError>;
  linkLocationCredential?: Maybe<LinkLocationCredential>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveOrderContentItemInput = {
  content: Scalars["String"];
  orderId: Scalars["Int"];
  title: Scalars["String"];
};

export type SaveOrderContentItemResponse = {
  __typename?: "SaveOrderContentItemResponse";
  contentItem: ContentItem;
};

export type SavePartnerAnalyticCredentialInput = {
  instructions?: InputMaybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  password?: InputMaybe<Scalars["String"]>;
  userName?: InputMaybe<Scalars["String"]>;
};

export type SavePartnerAnalyticCredentialResponse = {
  __typename?: "SavePartnerAnalyticCredentialResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
  partnerAnalyticCredential?: Maybe<PartnerAnalyticCredential>;
};

export type SaveProposedOrderTopicInput = {
  customerKeywordId: Scalars["Int"];
  customerProposalId: Scalars["Int"];
  orderId: Scalars["Int"];
  taskId: Scalars["Int"];
  topic: Scalars["String"];
};

export type SaveProposedOrderTopicResponse = {
  __typename?: "SaveProposedOrderTopicResponse";
  proposedOrderTopic: ProposedOrderTopic;
};

export type SaveTaskContentIdeationInput = {
  idea: Scalars["String"];
  taskId: Scalars["Int"];
};

export type SaveTaskContentIdeationResponse = {
  __typename?: "SaveTaskContentIdeationResponse";
  contentIdeation: ContentIdeation;
  task: Task;
};

export type SaveTaskContentItemInput = {
  content: Scalars["String"];
  ignoreContentTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SaveTaskContentItemResponse = {
  __typename?: "SaveTaskContentItemResponse";
  contentItem: ContentItem;
  task?: Maybe<Task>;
};

export type SaveTaskGbpItemInput = {
  content: Scalars["String"];
  readMoreUrl?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
};

export type SaveTaskGbpItemResponse = {
  __typename?: "SaveTaskGbpItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveTaskTitleMetaDescriptionInput = {
  isFromReview?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
  titleMetaDescriptions: Array<TitleMetaDescriptionInput>;
};

export type SaveTaskTitleMetaDescriptionResponse = {
  __typename?: "SaveTaskTitleMetaDescriptionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveUserGrammarTestResponseInput = {
  answerId: Scalars["Int"];
};

export type SaveUserGrammarTestResponseResponse = {
  __typename?: "SaveUserGrammarTestResponseResponse";
  ok: Scalars["Boolean"];
};

export type SaveUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type SaveUserProfessionalResponse = {
  __typename?: "SaveUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userProfessional: UserProfessional;
};

export type SaveUserWorkInterestsInput = {
  interests: Array<Scalars["String"]>;
};

export type SaveUserWorkInterestsResponse = {
  __typename?: "SaveUserWorkInterestsResponse";
  interests: Array<Scalars["String"]>;
};

export type SaveWritingSampleInput = {
  content: Scalars["String"];
  isFinished: Scalars["Boolean"];
  title: Scalars["String"];
};

export type SaveWritingSampleResponse = {
  __typename?: "SaveWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userWritingSample?: Maybe<UserWritingSample>;
};

export type SelectWritingSampleTopicInput = {
  writingSampleTopicId?: InputMaybe<Scalars["Int"]>;
};

export type SelectWritingSampleTopicResponse = {
  __typename?: "SelectWritingSampleTopicResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SendEmailManagedSeoInput = {
  customerId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type SendEmailManagedSeoResponse = {
  __typename?: "SendEmailManagedSeoResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SignupCsUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAuthorizedToWorkInUS: Scalars["Boolean"];
  lastName: Scalars["String"];
  password: Scalars["String"];
};

export type SignupCsUserResponse = {
  __typename?: "SignupCSUserResponse";
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Site = {
  __typename?: "Site";
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  deployStatus: SiteDeployStatus;
  domainId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastPublishedAt?: Maybe<Scalars["DateTime"]>;
  siteTemplate?: Maybe<SiteTemplate>;
  siteTemplateId?: Maybe<Scalars["Int"]>;
};

export type SiteConnection = {
  __typename?: "SiteConnection";
  nodes: Array<Site>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type SiteDeployStatus = {
  __typename?: "SiteDeployStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SitePost = {
  __typename?: "SitePost";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  category?: Maybe<Category>;
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars["String"]>;
  status: SitePostStatus;
  statusId: Scalars["String"];
  taskId?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export type SitePostConnection = {
  __typename?: "SitePostConnection";
  nodes: Array<SitePost>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type SitePostStatus = {
  __typename?: "SitePostStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum SitePostsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type SiteTemplate = {
  __typename?: "SiteTemplate";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export enum SitesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type StartGrammarTestResponse = {
  __typename?: "StartGrammarTestResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type StartWritingSampleResponse = {
  __typename?: "StartWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type StripeOnboardingStatus = {
  __typename?: "StripeOnboardingStatus";
  helpText: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SubmitProposalReviewInput = {
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  reviewComment?: InputMaybe<Scalars["String"]>;
  reviewStatusId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
};

export type SubmitProposalReviewResponse = {
  __typename?: "SubmitProposalReviewResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SyncUberallLocationInput = {
  uberallLocationId: Scalars["Int"];
};

export type SyncUberallLocationResponse = {
  __typename?: "SyncUberallLocationResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type Task = {
  __typename?: "Task";
  assignedAt?: Maybe<Scalars["DateTime"]>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars["Int"]>;
  availableAt: Scalars["DateTime"];
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  checklist?: Maybe<Checklist>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  contentCalendarOrders: Array<Order>;
  contentIdeation?: Maybe<ContentIdeation>;
  contentItem?: Maybe<ContentItem>;
  createdAt: Scalars["DateTime"];
  creativesScaleTaskStatus?: Maybe<CreativesScaleTaskStatus>;
  currentSubTask?: Maybe<Task>;
  customer: Customer;
  customerId: Scalars["Int"];
  customerKeywordProposal?: Maybe<CustomerKeywordProposal>;
  customerProposal?: Maybe<CustomerProposal>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  gbpItem?: Maybe<GbpItem>;
  id: Scalars["Int"];
  isAssignedToViewer: Scalars["Boolean"];
  keyword?: Maybe<Scalars["String"]>;
  landingPageUrl?: Maybe<Scalars["String"]>;
  order: Order;
  orderId: Scalars["Int"];
  paymentAmount?: Maybe<Scalars["Float"]>;
  paymentStatus?: Maybe<TaskPaymentStatus>;
  product: Product;
  productId: Scalars["String"];
  publishPostOrders: Array<Order>;
  reviewComment?: Maybe<Scalars["String"]>;
  reviewStatus?: Maybe<TaskReviewStatus>;
  reviewTargetTask?: Maybe<Task>;
  reviewTargetTaskId?: Maybe<Scalars["Int"]>;
  reviewTasks: TaskConnection;
  sitePost?: Maybe<SitePost>;
  status: TaskStatus;
  subTasks: TaskConnection;
  taskType: TaskType;
  taskTypeId: Scalars["String"];
  titleMetaDescriptionItem?: Maybe<TitleMetaDescriptionItem>;
  topic?: Maybe<Scalars["String"]>;
  validationCheckSummary?: Maybe<ValidationCheckSummary>;
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars["Int"]>;
  workflow?: Maybe<Workflow>;
  workflowId: Scalars["Int"];
};

export type TaskReviewTasksArgs = {
  isFulfilledByCustomer?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  reviewStatusId?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TasksSort>;
};

export type TaskSubTasksArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type TaskConnection = {
  __typename?: "TaskConnection";
  nodes: Array<Task>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type TaskCount = {
  __typename?: "TaskCount";
  count: Scalars["Int"];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  rejectedCount: Scalars["Int"];
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
};

export type TaskPaymentStatus = {
  __typename?: "TaskPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskReport = {
  __typename?: "TaskReport";
  completedAt: Scalars["DateTime"];
  dayNumber: Scalars["Int"];
  taskCount?: Maybe<Array<TaskCount>>;
};

export type TaskReviewStatus = {
  __typename?: "TaskReviewStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskStatus = {
  __typename?: "TaskStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskType = {
  __typename?: "TaskType";
  defaultVendorId: Scalars["Int"];
  id: Scalars["String"];
  isFulfilledByCustomer: Scalars["Boolean"];
  name: Scalars["String"];
};

export type TaskTypeConnection = {
  __typename?: "TaskTypeConnection";
  nodes: Array<TaskType>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum TasksSort {
  CompletedAtAsc = "completed_at_asc",
  CompletedAtDesc = "completed_at_desc",
  IdAsc = "id_asc",
  IdDesc = "id_desc",
  OrderStartAsc = "order_start_asc",
  OrderStartDesc = "order_start_desc",
  Priority = "priority",
  WriterAsc = "writer_asc",
  WriterDesc = "writer_desc",
}

export type TitleMetaDescription = {
  __typename?: "TitleMetaDescription";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  metaDescription: Scalars["String"];
  titleTag: Scalars["String"];
  url: Scalars["String"];
};

export type TitleMetaDescriptionInput = {
  id?: InputMaybe<Scalars["Int"]>;
  metaDescription: Scalars["String"];
  titleTag: Scalars["String"];
  url: Scalars["String"];
};

export type TitleMetaDescriptionItem = {
  __typename?: "TitleMetaDescriptionItem";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  titleMetaDescriptions: Array<TitleMetaDescription>;
};

export type TopicKeywordResponse = {
  __typename?: "TopicKeywordResponse";
  topicSuggestions: Scalars["String"];
};

export type UberallCategory = {
  __typename?: "UberallCategory";
  fullName: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  parent: Scalars["Int"];
  selectable: Scalars["Boolean"];
};

export type UberallListingDetails = {
  __typename?: "UberallListingDetails";
  action?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  listingUrl?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  typeName?: Maybe<Scalars["String"]>;
};

export type UberallListingDetailsResponse = {
  __typename?: "UberallListingDetailsResponse";
  countListingsActionRequired?: Maybe<Scalars["Int"]>;
  countListingsBeingUpdated?: Maybe<Scalars["Int"]>;
  countListingsInSync?: Maybe<Scalars["Int"]>;
  countListingsLinked?: Maybe<Scalars["Int"]>;
  countListingsSubmitted?: Maybe<Scalars["Int"]>;
  listings?: Maybe<Array<UberallListingDetails>>;
  totalListings?: Maybe<Scalars["Int"]>;
};

export type UpdateAmTaskInput = {
  amTaskId: Scalars["Int"];
  completedAt?: InputMaybe<Scalars["Date"]>;
  info?: InputMaybe<Scalars["String"]>;
  taskType?: InputMaybe<Scalars["String"]>;
};

export type UpdateAmTaskResponse = {
  __typename?: "UpdateAmTaskResponse";
  amTask?: Maybe<AmTask>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCategoryInput = {
  categoryId: Scalars["Int"];
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateCategoryResponse = {
  __typename?: "UpdateCategoryResponse";
  category: Category;
};

export type UpdateChecklistItemInput = {
  checklistItemId: Scalars["Int"];
  completedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateChecklistItemResponse = {
  __typename?: "UpdateChecklistItemResponse";
  checklistItem: ChecklistItem;
};

export type UpdateCreativeScaleUserInput = {
  countryOfResidence?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  isEligibleForPayments?: InputMaybe<Scalars["Boolean"]>;
  isOptingInForUpdates?: InputMaybe<Scalars["Boolean"]>;
  isRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  isStripeOnboardingReminderEnabled?: InputMaybe<Scalars["Boolean"]>;
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  writerClassification?: InputMaybe<Scalars["String"]>;
};

export type UpdateCreativeScaleUserResponse = {
  __typename?: "UpdateCreativeScaleUserResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDisplay?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  isListed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  uberallCategoryId?: InputMaybe<Scalars["Int"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerBusinessListingSpecResponse = {
  __typename?: "UpdateCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerCampaignInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  customerCampaignId: Scalars["Int"];
  endsAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  startsAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateCustomerCampaignResponse = {
  __typename?: "UpdateCustomerCampaignResponse";
  customerCampaign: CustomerCampaign;
};

export type UpdateCustomerConversationInput = {
  customerConversationId: Scalars["String"];
  helpRequestedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateCustomerConversationResponse = {
  __typename?: "UpdateCustomerConversationResponse";
  customerConversation: CustomerConversation;
};

export type UpdateCustomerHoursOfOperationItemInput = {
  closeAt?: InputMaybe<Scalars["String"]>;
  dayIndex?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  isOpen?: InputMaybe<Scalars["Boolean"]>;
  openAt?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerInput = {
  accountManagerUserId?: InputMaybe<Scalars["Int"]>;
  address?: InputMaybe<Scalars["String"]>;
  analyticsReportingInlineFrameUrl?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  customerId: Scalars["Int"];
  gbpUrl?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isBlogFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  isGuestArticleFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  rcmAccountManagerUserId?: InputMaybe<Scalars["Int"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  isTrackingRank?: InputMaybe<Scalars["Boolean"]>;
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordProposalInput = {
  customerConversationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordProposalResponse = {
  __typename?: "UpdateCustomerKeywordProposalResponse";
  customerKeywordProposal?: Maybe<CustomerKeywordProposal>;
};

export type UpdateCustomerKeywordResponse = {
  __typename?: "UpdateCustomerKeywordResponse";
  customerKeyword?: Maybe<CustomerKeyword>;
};

export type UpdateCustomerNoteInput = {
  content: Scalars["String"];
  noteId: Scalars["Int"];
};

export type UpdateCustomerNoteResponse = {
  __typename?: "UpdateCustomerNoteResponse";
  note?: Maybe<Note>;
};

export type UpdateCustomerProposalInput = {
  customerProposalId: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerProposalResponse = {
  __typename?: "UpdateCustomerProposalResponse";
  customerProposal?: Maybe<CustomerProposal>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerResponse = {
  __typename?: "UpdateCustomerResponse";
  customer?: Maybe<Customer>;
};

export type UpdateCustomerStatusChangeInput = {
  customerStatusChangeId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  reason?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerStatusChangeResponse = {
  __typename?: "UpdateCustomerStatusChangeResponse";
  customerStatusChange: CustomerStatusChange;
};

export type UpdateCustomerUserInput = {
  customerUserId: Scalars["Int"];
  isActive: Scalars["Boolean"];
};

export type UpdateCustomerUserResponse = {
  __typename?: "UpdateCustomerUserResponse";
  customerUser: CustomerUser;
};

export type UpdateFeedbackContentItemInput = {
  feedback?: InputMaybe<Scalars["String"]>;
  feedbackContentItemId: Scalars["Int"];
  rating?: InputMaybe<Scalars["Int"]>;
};

export type UpdateFeedbackContentItemResponse = {
  __typename?: "UpdateFeedbackContentItemResponse";
  error?: Maybe<ResponseError>;
  feedbackContentItem?: Maybe<FeedbackContentItem>;
  ok: Scalars["Boolean"];
};

export type UpdateFulfillmentRuleInput = {
  fulfillmentRuleId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type UpdateFulfillmentRuleResponse = {
  __typename?: "UpdateFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  fulfillmentRule?: Maybe<FulfillmentRule>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationCategoriesInput = {
  categoryIds: Array<Scalars["Int"]>;
  linkLocationId: Scalars["Int"];
};

export type UpdateLinkLocationCategoriesResponse = {
  __typename?: "UpdateLinkLocationCategoriesResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationInput = {
  allowsCbdContent?: InputMaybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: InputMaybe<Scalars["Boolean"]>;
  authorityScore?: InputMaybe<Scalars["Int"]>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  internalQuality?: InputMaybe<Scalars["Int"]>;
  linkLocationId: Scalars["Int"];
  minimumWordCount?: InputMaybe<Scalars["Int"]>;
  monthlyTraffic?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  spam?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["String"]>;
  typeId?: InputMaybe<Scalars["String"]>;
};

export type UpdateLinkLocationMetricsInput = {
  linkLocationId: Scalars["Int"];
};

export type UpdateLinkLocationMetricsResponse = {
  __typename?: "UpdateLinkLocationMetricsResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationResponse = {
  __typename?: "UpdateLinkLocationResponse";
  error?: Maybe<ResponseError>;
  linkLocation?: Maybe<LinkLocation>;
  ok: Scalars["Boolean"];
};

export type UpdateOrderContentItemInput = {
  isPostPublished: Scalars["Boolean"];
  orderContentItemId: Scalars["Int"];
};

export type UpdateOrderContentItemResponse = {
  __typename?: "UpdateOrderContentItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentItem?: Maybe<OrderContentItem>;
};

export type UpdateOrderContentStrategyInput = {
  clientProvidedInstructions?: InputMaybe<Scalars["String"]>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  focusPoints?: InputMaybe<Scalars["String"]>;
  isProposed?: InputMaybe<Scalars["Boolean"]>;
  linkTo?: InputMaybe<Scalars["String"]>;
  orderContentStrategyId: Scalars["Int"];
  orderHaloKeywords?: InputMaybe<Array<Scalars["Int"]>>;
  pageType?: InputMaybe<Scalars["String"]>;
  purposeOfContent?: InputMaybe<Scalars["String"]>;
  sampleContent?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  voice?: InputMaybe<Scalars["String"]>;
  webPage?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrderContentStrategyResponse = {
  __typename?: "UpdateOrderContentStrategyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentStrategy?: Maybe<OrderContentStrategy>;
};

export type UpdateOrderExternalContentSpecInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  orderExternalContentSpecId: Scalars["Int"];
  trustFlow?: InputMaybe<Scalars["Int"]>;
};

export type UpdateOrderExternalContentSpecResponse = {
  __typename?: "UpdateOrderExternalContentSpecResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
};

export type UpdateOrderGbpItemInput = {
  content: Scalars["String"];
  isProfilePublished: Scalars["Boolean"];
  orderGbpItemId: Scalars["Int"];
  readMoreUrl?: InputMaybe<Scalars["String"]>;
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrderGbpItemResponse = {
  __typename?: "UpdateOrderGbpItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderGbpItem?: Maybe<OrderGbpItem>;
};

export type UpdateOrderInput = {
  customerCampaignId?: InputMaybe<Scalars["Int"]>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  linkLocationId?: InputMaybe<Scalars["Int"]>;
  orderId: Scalars["Int"];
  publisherId?: InputMaybe<Scalars["Int"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  resultUrl?: InputMaybe<Scalars["String"]>;
  resultUrlDomainAuthority?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  targetLinkLocationId?: InputMaybe<Scalars["Int"]>;
  targetResultUrl?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  validateResultUrl?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateOrderResponse = {
  __typename?: "UpdateOrderResponse";
  order: Order;
};

export type UpdateOrderTitleMetaDescriptionItemInput = {
  isPublished: Scalars["Boolean"];
  orderTitleMetaDescriptionItemId: Scalars["Int"];
};

export type UpdateOrderTitleMetaDescriptionItemResponse = {
  __typename?: "UpdateOrderTitleMetaDescriptionItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderTitleMetaDescriptionItem?: Maybe<OrderTitleMetaDescriptionItem>;
};

export type UpdatePartnerBillingContactInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  partnerBillingContactId: Scalars["Int"];
};

export type UpdatePartnerBillingContactResponse = {
  __typename?: "UpdatePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerBillingContact?: Maybe<PartnerBillingContact>;
};

export type UpdatePartnerInput = {
  autopayPartnerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  billingEmail?: InputMaybe<Scalars["String"]>;
  emailLogoS3FileId?: InputMaybe<Scalars["String"]>;
  isAutopayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  portal?: InputMaybe<Scalars["String"]>;
  reportingLogoS3FileId?: InputMaybe<Scalars["String"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]>;
};

export type UpdatePartnerPackageInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerPackageId: Scalars["Int"];
};

export type UpdatePartnerPackageItemInput = {
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
  partnerPackageItemId: Scalars["Int"];
  quantity?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePartnerPackageItemResponse = {
  __typename?: "UpdatePartnerPackageItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackageItem?: Maybe<PartnerPackageItem>;
};

export type UpdatePartnerPackageResponse = {
  __typename?: "UpdatePartnerPackageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackage?: Maybe<PartnerPackage>;
};

export type UpdatePartnerProductInput = {
  activeAt?: InputMaybe<Scalars["DateTime"]>;
  checklistDefinitionId?: InputMaybe<Scalars["Int"]>;
  inactiveAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerProductId: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePartnerProductPriceInput = {
  partnerProductPriceId: Scalars["Int"];
  price?: InputMaybe<Scalars["Float"]>;
  startsAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdatePartnerProductPriceResponse = {
  __typename?: "UpdatePartnerProductPriceResponse";
  partnerProductPrice: PartnerProductPrice;
};

export type UpdatePartnerProductResponse = {
  __typename?: "UpdatePartnerProductResponse";
  partnerProduct?: Maybe<PartnerProduct>;
};

export type UpdatePartnerProductWholesalePriceInput = {
  effectiveAt?: InputMaybe<Scalars["DateTime"]>;
  partnerProductWholesalePriceId: Scalars["Int"];
  wholesaleAmount?: InputMaybe<Scalars["Float"]>;
};

export type UpdatePartnerProductWholesalePriceResponse = {
  __typename?: "UpdatePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
};

export type UpdatePartnerResponse = {
  __typename?: "UpdatePartnerResponse";
  partner?: Maybe<Partner>;
};

export type UpdatePartnerUserInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  partnerUserId: Scalars["Int"];
};

export type UpdatePartnerUserResponse = {
  __typename?: "UpdatePartnerUserResponse";
  partnerUser: PartnerUser;
};

export type UpdateProposedCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  proposedCustomerKeywordId: Scalars["Int"];
};

export type UpdateProposedCustomerKeywordResponse = {
  __typename?: "UpdateProposedCustomerKeywordResponse";
  proposedCustomerKeyword: ProposedCustomerKeyword;
};

export type UpdatePublisherInput = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isInvoiceRequired?: InputMaybe<Scalars["Boolean"]>;
  isSlowPartner?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentMethod?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  publisherId: Scalars["Int"];
};

export type UpdatePublisherLinkLocationInput = {
  isRCMPublishing?: InputMaybe<Scalars["Boolean"]>;
  publisherLinkLocationId: Scalars["Int"];
};

export type UpdatePublisherLinkLocationResponse = {
  __typename?: "UpdatePublisherLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
};

export type UpdatePublisherPaymentInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherPaymentId: Scalars["Int"];
  statusId?: InputMaybe<Scalars["String"]>;
};

export type UpdatePublisherPaymentResponse = {
  __typename?: "UpdatePublisherPaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherPayment: PublisherPayment;
};

export type UpdatePublisherResponse = {
  __typename?: "UpdatePublisherResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisher?: Maybe<Publisher>;
};

export type UpdateRcmWriterTasksInput = {
  isFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  taskIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type UpdateRcmWriterTasksResponse = {
  __typename?: "UpdateRcmWriterTasksResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateSitePostForTaskInput = {
  content: Scalars["String"];
  taskId: Scalars["Int"];
  title: Scalars["String"];
};

export type UpdateSitePostForTaskResponse = {
  __typename?: "UpdateSitePostForTaskResponse";
  sitePost: SitePost;
};

export type UpdateSitePostInput = {
  categoryId?: InputMaybe<Scalars["Int"]>;
  content?: InputMaybe<Scalars["String"]>;
  siteId?: InputMaybe<Scalars["String"]>;
  sitePostId: Scalars["Int"];
  statusId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateSitePostResponse = {
  __typename?: "UpdateSitePostResponse";
  sitePost: SitePost;
};

export type UpdateTaskInput = {
  assignedUserId?: InputMaybe<Scalars["Int"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  isApproved?: InputMaybe<Scalars["Boolean"]>;
  reviewComment?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UpdateTaskResponse = {
  __typename?: "UpdateTaskResponse";
  task: Task;
};

export type UpdateUserInput = {
  calendarUrl?: InputMaybe<Scalars["String"]>;
  emailPreference?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  profilePictureS3FileId?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type UpdateUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type UpdateUserProfessionalResponse = {
  __typename?: "UpdateUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userPorfessional?: Maybe<UserProfessional>;
};

export type UpdateUserResponse = {
  __typename?: "UpdateUserResponse";
  user?: Maybe<User>;
};

export type UpdateVendorInput = {
  name?: InputMaybe<Scalars["String"]>;
  vendorId: Scalars["Int"];
};

export type UpdateVendorResponse = {
  __typename?: "UpdateVendorResponse";
  vendor?: Maybe<Vendor>;
};

export type UpdateVendorUserInput = {
  isActive: Scalars["Boolean"];
  vendorUserId: Scalars["Int"];
};

export type UpdateVendorUserResponse = {
  __typename?: "UpdateVendorUserResponse";
  vendorUser: VendorUser;
};

export type UpdateWritingSampleTopicInput = {
  additionalInformation?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  primaryKeyword?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  voice?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
  writingSampleTopicId: Scalars["Int"];
};

export type UpdateWritingSampleTopicResponse = {
  __typename?: "UpdateWritingSampleTopicResponse";
  writingSampleTopic: WritingSampleTopic;
};

export type User = {
  __typename?: "User";
  calendarUrl?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  emailPreference?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  isAdmin: Scalars["Boolean"];
  isEmailConfirmed: Scalars["Boolean"];
  isPasswordSet: Scalars["Boolean"];
  isSuperAdmin: Scalars["Boolean"];
  isTracking: Scalars["Boolean"];
  lastName: Scalars["String"];
  partnerUsers: Array<PartnerUser>;
  partners: Array<Partner>;
  profilePictureFile?: Maybe<File>;
  timezone: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  userProfessional?: Maybe<UserProfessional>;
  userTaskPermissions: Array<UserTaskPermission>;
  vendorUsers: Array<VendorUser>;
};

export type UserUserTaskPermissionsArgs = {
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  nodes: Array<User>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type UserGrammarTest = {
  __typename?: "UserGrammarTest";
  activeQuestion?: Maybe<UserGrammarTestQuestion>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  expiresAt: Scalars["DateTime"];
  id: Scalars["Int"];
  result?: Maybe<Scalars["String"]>;
  startedAt: Scalars["DateTime"];
  userId: Scalars["Int"];
};

export type UserGrammarTestQuestion = {
  __typename?: "UserGrammarTestQuestion";
  createdAt: Scalars["DateTime"];
  grammarTestQuestion: GrammarTestQuestion;
  grammarTestQuestionId: Scalars["Int"];
  id: Scalars["Int"];
  isCorrect?: Maybe<Scalars["Boolean"]>;
  option1Answer?: Maybe<GrammarTestQuestionAnswer>;
  option1AnswerId: Scalars["Int"];
  option2Answer?: Maybe<GrammarTestQuestionAnswer>;
  option2AnswerId: Scalars["Int"];
  option3Answer?: Maybe<GrammarTestQuestionAnswer>;
  option3AnswerId?: Maybe<Scalars["Int"]>;
  option4Answer?: Maybe<GrammarTestQuestionAnswer>;
  option4AnswerId?: Maybe<Scalars["Int"]>;
  questionIndex: Scalars["Int"];
  selectedAnswerId?: Maybe<Scalars["Int"]>;
  userId: Scalars["Int"];
};

export type UserProfessional = {
  __typename?: "UserProfessional";
  createdAt: Scalars["DateTime"];
  linkedInProfileUrl?: Maybe<Scalars["String"]>;
  resumeFile?: Maybe<File>;
  user: User;
  userId: Scalars["Int"];
};

export type UserTaskPermission = {
  __typename?: "UserTaskPermission";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
  userId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type UserTaskReport = {
  __typename?: "UserTaskReport";
  taskReports?: Maybe<Array<TaskReport>>;
  user: User;
  userId: Scalars["Int"];
};

export type UserWritingSample = {
  __typename?: "UserWritingSample";
  contentItem?: Maybe<ContentItem>;
  contentItemId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  reviewStatus?: Maybe<UserWritingSampleReviewStatus>;
  reviewerUser?: Maybe<User>;
  user: User;
  validationCheckSummary?: Maybe<ValidationCheckSummary>;
  writingSampleTopic?: Maybe<WritingSampleTopic>;
  writingSampleTopicId?: Maybe<Scalars["Int"]>;
};

export type UserWritingSampleConnection = {
  __typename?: "UserWritingSampleConnection";
  nodes: Array<UserWritingSample>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type UserWritingSampleReviewStatus = {
  __typename?: "UserWritingSampleReviewStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum UserWritingSamplesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export enum UsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ValidationCheck = {
  __typename?: "ValidationCheck";
  actualValue: Scalars["String"];
  failureCriteria: Scalars["String"];
  helpText: Scalars["String"];
  id: Scalars["String"];
  isRequiredToPass: Scalars["Boolean"];
  isValid: Scalars["Boolean"];
  name: Scalars["String"];
  successCriteria: Scalars["String"];
};

export type ValidationCheckSummary = {
  __typename?: "ValidationCheckSummary";
  checks: Array<ValidationCheck>;
  isValid: Scalars["Boolean"];
};

export type Vendor = {
  __typename?: "Vendor";
  associatedPartnerProducts: PartnerProductConnection;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isExternal: Scalars["Boolean"];
  name: Scalars["String"];
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars["Int"]>;
  vendorUsers: VendorUserConnection;
};

export type VendorAssociatedPartnerProductsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type VendorVendorUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type VendorConnection = {
  __typename?: "VendorConnection";
  nodes: Array<Vendor>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type VendorUser = {
  __typename?: "VendorUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  user: User;
  userId: Scalars["Int"];
  userTaskPermissions: Array<UserTaskPermission>;
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type VendorUserConnection = {
  __typename?: "VendorUserConnection";
  nodes: Array<VendorUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum VendorsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type VerifyGuestPostInput = {
  orderIds: Array<Scalars["Int"]>;
};

export type VerifyGuestPostResponse = {
  __typename?: "VerifyGuestPostResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type Viewer = {
  __typename?: "Viewer";
  accessibleCustomerIds: Array<Scalars["Int"]>;
  accessiblePublisherIds: Array<Scalars["Int"]>;
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  hasSLPCustomer?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<User>;
};

export type WorkInterest = {
  __typename?: "WorkInterest";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Workflow = {
  __typename?: "Workflow";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customer: Customer;
  id: Scalars["Int"];
  internalStatus: Scalars["String"];
  lastUpdatedAt: Scalars["DateTime"];
  notes: NoteConnection;
  order: Order;
  status: WorkflowStatus;
  tasks: TaskConnection;
  type: Scalars["String"];
};

export type WorkflowNotesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type WorkflowTasksArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<TasksSort>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type WorkflowConnection = {
  __typename?: "WorkflowConnection";
  nodes: Array<Workflow>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type WorkflowStatus = {
  __typename?: "WorkflowStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type WritingSampleTopic = {
  __typename?: "WritingSampleTopic";
  additionalInformation?: Maybe<Scalars["String"]>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["Date"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  primaryKeyword?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
  voice?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type WritingSampleTopicConnection = {
  __typename?: "WritingSampleTopicConnection";
  nodes: Array<WritingSampleTopic>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreateLinkLocationMutationVariables = Exact<{
  input: CreateLinkLocationInput;
}>;

export type CreateLinkLocationMutation = {
  __typename?: "Mutation";
  createLinkLocation: {
    __typename?: "CreateLinkLocationResponse";
    ok: boolean;
    linkLocation?: { __typename?: "LinkLocation"; id: number } | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CreatePublisherLinkLocationMutationVariables = Exact<{
  input: CreatePublisherLinkLocationInput;
}>;

export type CreatePublisherLinkLocationMutation = {
  __typename?: "Mutation";
  createPublisherLinkLocation: {
    __typename?: "CreatePublisherLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type SaveLinkLocationCredentialMutationVariables = Exact<{
  input: SaveLinkLocationCredentialInput;
}>;

export type SaveLinkLocationCredentialMutation = {
  __typename?: "Mutation";
  saveLinkLocationCredential: {
    __typename?: "SaveLinkLocationCredentialResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type UpdateLinkLocationCategoriesMutationVariables = Exact<{
  input: UpdateLinkLocationCategoriesInput;
}>;

export type UpdateLinkLocationCategoriesMutation = {
  __typename?: "Mutation";
  updateLinkLocationCategories: {
    __typename?: "UpdateLinkLocationCategoriesResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CreatePublisherUserMutationVariables = Exact<{
  input: CreatePublisherUserInput;
}>;

export type CreatePublisherUserMutation = {
  __typename?: "Mutation";
  createPublisherUser: {
    __typename?: "CreatePublisherUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CategoriesForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type CategoriesForSelectorQuery = {
  __typename?: "Query";
  categories: {
    __typename?: "CategoryConnection";
    nodes: Array<{ __typename?: "Category"; id: number; name: string }>;
  };
};

export type LinkLocationStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type LinkLocationStatusesQuery = {
  __typename?: "Query";
  linkLocationStatuses: Array<{ __typename?: "LinkLocationStatus"; id: string; name: string }>;
};

export type AuthManager_ViewerFragment = {
  __typename?: "Viewer";
  accessiblePublisherIds: Array<number>;
  user?: { __typename?: "User"; id: number; fullName: string } | null;
};

export type AuthManager_ViewerUserFragment = { __typename?: "User"; id: number; fullName: string };

export type AuthManager_PublisherFragment = { __typename?: "Publisher"; id: number; name: string };

export type AuthManagerQueryVariables = Exact<{ [key: string]: never }>;

export type AuthManagerQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    accessiblePublisherIds: Array<number>;
    user?: { __typename?: "User"; id: number; fullName: string } | null;
  };
};

export type AuthPublisherQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AuthPublisherQuery = {
  __typename?: "Query";
  publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
};

export type LogoutUserMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutUserMutation = {
  __typename?: "Mutation";
  logoutUser: { __typename?: "LogoutUserResponse"; isLoggedOut: boolean };
};

export type PublisherAccountQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type PublisherAccountQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    id: number;
    name: string;
    contactEmail?: string | null;
    paymentMethod?: string | null;
    paymentMethodId?: string | null;
  } | null;
};

export type PublisherAccount_UpdatePublisherMutationVariables = Exact<{
  input: UpdatePublisherInput;
}>;

export type PublisherAccount_UpdatePublisherMutation = {
  __typename?: "Mutation";
  updatePublisher: {
    __typename?: "UpdatePublisherResponse";
    publisher?: {
      __typename?: "Publisher";
      id: number;
      name: string;
      contactEmail?: string | null;
      paymentMethod?: string | null;
      paymentMethodId?: string | null;
      instructions?: string | null;
    } | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserMutation = {
  __typename?: "Mutation";
  loginUser: { __typename?: "LoginUserResponse"; isLoggedIn?: boolean | null };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "UpdateUserResponse"; user?: { __typename?: "User"; id: number } | null };
};

export type UserProfileQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type UserProfileQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    emailPreference?: string | null;
  } | null;
};

export type AdminPublisherMetricQueryVariables = Exact<{
  publisherId: Scalars["Int"];
}>;

export type AdminPublisherMetricQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    id: number;
    paidAmount30days: number;
    paidAmountTotal: number;
    publishedArticles7days: { __typename?: "OrderConnection"; totalCount: number };
    publishedArticles30days: { __typename?: "OrderConnection"; totalCount: number };
    publishedArticlesTotal: { __typename?: "OrderConnection"; totalCount: number };
  } | null;
};

export type RequestedOrderFragment = {
  __typename?: "Order";
  id: number;
  targetResultUrl?: string | null;
  topic?: string | null;
  proposalTask?: {
    __typename?: "Task";
    id: number;
    status: { __typename?: "TaskStatus"; id: string };
    assignedUser?: { __typename?: "User"; id: number; email: string; fullName: string } | null;
  } | null;
  status: { __typename?: "OrderStatus"; id: string; name: string };
  publisherPayment?: {
    __typename?: "PublisherPayment";
    id: number;
    processor?: string | null;
    processorReferenceId?: string | null;
  } | null;
  publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
  targetLinkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
};

export type PublishedOrderFragment = {
  __typename?: "Order";
  id: number;
  completedAt?: Date | null;
  resultUrl?: string | null;
  topic?: string | null;
  status: { __typename?: "OrderStatus"; id: string; name: string };
  publisherPayment?: {
    __typename?: "PublisherPayment";
    id: number;
    processor?: string | null;
    processorReferenceId?: string | null;
  } | null;
  publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
  linkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
};

export type AdminPublishedOrdersQueryVariables = Exact<{
  publisherId: Scalars["Int"];
  requestLimit?: InputMaybe<Scalars["Int"]>;
  requestOffset?: InputMaybe<Scalars["Int"]>;
  requestSort?: InputMaybe<OrdersSort>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
}>;

export type AdminPublishedOrdersQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    requestedOrders: {
      __typename?: "OrderConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Order";
        id: number;
        targetResultUrl?: string | null;
        topic?: string | null;
        proposalTask?: {
          __typename?: "Task";
          id: number;
          status: { __typename?: "TaskStatus"; id: string };
          assignedUser?: { __typename?: "User"; id: number; email: string; fullName: string } | null;
        } | null;
        status: { __typename?: "OrderStatus"; id: string; name: string };
        publisherPayment?: {
          __typename?: "PublisherPayment";
          id: number;
          processor?: string | null;
          processorReferenceId?: string | null;
        } | null;
        publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
        targetLinkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
      }>;
    };
    publishedOrders: {
      __typename?: "OrderConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Order";
        id: number;
        completedAt?: Date | null;
        resultUrl?: string | null;
        topic?: string | null;
        status: { __typename?: "OrderStatus"; id: string; name: string };
        publisherPayment?: {
          __typename?: "PublisherPayment";
          id: number;
          processor?: string | null;
          processorReferenceId?: string | null;
        } | null;
        publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
        linkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
      }>;
    };
  } | null;
};

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;

export type RequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset: { __typename?: "RequestPasswordResetResponse"; wasRequested: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: { __typename?: "ResetPasswordResponse"; wasReset: boolean };
};

export type CreatePublisherMutationVariables = Exact<{
  input: CreatePublisherInput;
}>;

export type CreatePublisherMutation = {
  __typename?: "Mutation";
  createPublisher: {
    __typename?: "CreatePublisherResponse";
    ok: boolean;
    publisher?: { __typename?: "Publisher"; id: number } | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type UpdateLinkLocationDetailsMutationVariables = Exact<{
  input: UpdateLinkLocationInput;
  categoryInput: UpdateLinkLocationCategoriesInput;
}>;

export type UpdateLinkLocationDetailsMutation = {
  __typename?: "Mutation";
  updateLinkLocation: {
    __typename?: "UpdateLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
  updateLinkLocationCategories: {
    __typename?: "UpdateLinkLocationCategoriesResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type UpdatePublisherLinkLocationMutationVariables = Exact<{
  input: UpdatePublisherLinkLocationInput;
}>;

export type UpdatePublisherLinkLocationMutation = {
  __typename?: "Mutation";
  updatePublisherLinkLocation: {
    __typename?: "UpdatePublisherLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type SiteDetailsSaveLinkLocationCredentialMutationVariables = Exact<{
  input: SaveLinkLocationCredentialInput;
}>;

export type SiteDetailsSaveLinkLocationCredentialMutation = {
  __typename?: "Mutation";
  saveLinkLocationCredential: {
    __typename?: "SaveLinkLocationCredentialResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type LinkLocationDetailsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type LinkLocationDetailsQuery = {
  __typename?: "Query";
  linkLocation?: {
    __typename?: "LinkLocation";
    id: number;
    domain: string;
    domainAuthority: number;
    spam?: number | null;
    authorityScore?: number | null;
    monthlyTraffic?: number | null;
    notes?: string | null;
    minimumWordCount?: number | null;
    cost?: number | null;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhone?: string | null;
    statusId: string;
    linkLocationCategories: Array<{
      __typename?: "LinkLocationCategory";
      id: number;
      category: { __typename?: "Category"; id: number; name: string };
    }>;
    publisherLinkLocation?: {
      __typename?: "PublisherLinkLocation";
      id: number;
      isRCMPublishing?: boolean | null;
    } | null;
    linkLocationCredential?: {
      __typename?: "LinkLocationCredential";
      id: number;
      adminUrl: string;
      userName: string;
      password: string;
      instructions?: string | null;
    } | null;
  } | null;
};

export type SitesQueryVariables = Exact<{
  publisherId: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
}>;

export type SitesQuery = {
  __typename?: "Query";
  publisherLinkLocations: {
    __typename?: "PublisherLinkLocationConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "PublisherLinkLocation";
      id: number;
      linkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        domain: string;
        domainAuthority: number;
        authorityScore?: number | null;
        monthlyTraffic?: number | null;
        cost?: number | null;
        status: { __typename?: "LinkLocationStatus"; id: string; name: string };
        linkLocationCategories: Array<{
          __typename?: "LinkLocationCategory";
          id: number;
          category: { __typename?: "Category"; id: number; name: string };
        }>;
      } | null;
    }>;
  };
};

export type Sso_LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type Sso_LoginUserMutation = {
  __typename?: "Mutation";
  loginUser: { __typename?: "LoginUserResponse"; isLoggedIn?: boolean | null };
};

export type TaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type TaskWorkspaceQuery = {
  __typename?: "Query";
  task?: { __typename?: "Task"; id: number; taskType: { __typename?: "TaskType"; id: string; name: string } } | null;
};

export type SubmitProposalReviewMutationVariables = Exact<{
  input: SubmitProposalReviewInput;
}>;

export type SubmitProposalReviewMutation = {
  __typename?: "Mutation";
  submitProposalReview: {
    __typename?: "SubmitProposalReviewResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOrder: { __typename?: "UpdateOrderResponse"; order: { __typename?: "Order"; id: number } };
};

export type ProposalReviewTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ProposalReviewTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    status: { __typename?: "TaskStatus"; id: string; name: string };
    order: {
      __typename?: "Order";
      id: number;
      targetResultUrl?: string | null;
      targetLinkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
    };
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
    } | null;
  } | null;
};

export type PublisherUsersQueryVariables = Exact<{
  id: Scalars["Int"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PublisherUsersQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    id: number;
    publisherUsers: {
      __typename?: "PublisherUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "PublisherUser";
        user: { __typename?: "User"; id: number; fullName: string; email: string };
      }>;
    };
  } | null;
};

export const AuthManager_ViewerUserFragmentDoc = gql`
  fragment AuthManager_ViewerUser on User {
    id
    fullName
  }
`;
export const AuthManager_ViewerFragmentDoc = gql`
  fragment AuthManager_Viewer on Viewer {
    user {
      ...AuthManager_ViewerUser
    }
    accessiblePublisherIds
  }
  ${AuthManager_ViewerUserFragmentDoc}
`;
export const AuthManager_PublisherFragmentDoc = gql`
  fragment AuthManager_Publisher on Publisher {
    id
    name
  }
`;
export const RequestedOrderFragmentDoc = gql`
  fragment RequestedOrder on Order {
    id
    targetResultUrl
    topic
    proposalTask {
      id
      status {
        id
      }
      assignedUser {
        id
        email
        fullName
      }
    }
    status {
      id
      name
    }
    publisherPayment {
      id
      processor
      processorReferenceId
    }
    publisherPaymentStatus {
      id
      name
    }
    targetLinkLocation {
      id
      cost
    }
  }
`;
export const PublishedOrderFragmentDoc = gql`
  fragment PublishedOrder on Order {
    id
    completedAt
    resultUrl
    topic
    status {
      id
      name
    }
    publisherPayment {
      id
      processor
      processorReferenceId
    }
    publisherPaymentStatus {
      id
      name
    }
    linkLocation {
      id
      cost
    }
  }
`;
export const CreateLinkLocationDocument = gql`
  mutation CreateLinkLocation($input: CreateLinkLocationInput!) {
    createLinkLocation(input: $input) {
      ok
      linkLocation {
        id
      }
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreateLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  CreateLinkLocationMutation,
  CreateLinkLocationMutationVariables
>;

/**
 * __useCreateLinkLocationMutation__
 *
 * To run a mutation, you first call `useCreateLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkLocationMutation, { data, loading, error }] = useCreateLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLinkLocationMutation, CreateLinkLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateLinkLocationMutation, CreateLinkLocationMutationVariables>(
    CreateLinkLocationDocument,
    options
  );
}
export type CreateLinkLocationMutationHookResult = ReturnType<typeof useCreateLinkLocationMutation>;
export type CreateLinkLocationMutationResult = ApolloReactCommon.MutationResult<CreateLinkLocationMutation>;
export type CreateLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLinkLocationMutation,
  CreateLinkLocationMutationVariables
>;
export const CreatePublisherLinkLocationDocument = gql`
  mutation CreatePublisherLinkLocation($input: CreatePublisherLinkLocationInput!) {
    createPublisherLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreatePublisherLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  CreatePublisherLinkLocationMutation,
  CreatePublisherLinkLocationMutationVariables
>;

/**
 * __useCreatePublisherLinkLocationMutation__
 *
 * To run a mutation, you first call `useCreatePublisherLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherLinkLocationMutation, { data, loading, error }] = useCreatePublisherLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublisherLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePublisherLinkLocationMutation,
    CreatePublisherLinkLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePublisherLinkLocationMutation,
    CreatePublisherLinkLocationMutationVariables
  >(CreatePublisherLinkLocationDocument, options);
}
export type CreatePublisherLinkLocationMutationHookResult = ReturnType<typeof useCreatePublisherLinkLocationMutation>;
export type CreatePublisherLinkLocationMutationResult =
  ApolloReactCommon.MutationResult<CreatePublisherLinkLocationMutation>;
export type CreatePublisherLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePublisherLinkLocationMutation,
  CreatePublisherLinkLocationMutationVariables
>;
export const SaveLinkLocationCredentialDocument = gql`
  mutation SaveLinkLocationCredential($input: SaveLinkLocationCredentialInput!) {
    saveLinkLocationCredential(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveLinkLocationCredentialMutationFn = ApolloReactCommon.MutationFunction<
  SaveLinkLocationCredentialMutation,
  SaveLinkLocationCredentialMutationVariables
>;

/**
 * __useSaveLinkLocationCredentialMutation__
 *
 * To run a mutation, you first call `useSaveLinkLocationCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLinkLocationCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLinkLocationCredentialMutation, { data, loading, error }] = useSaveLinkLocationCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLinkLocationCredentialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveLinkLocationCredentialMutation,
    SaveLinkLocationCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveLinkLocationCredentialMutation, SaveLinkLocationCredentialMutationVariables>(
    SaveLinkLocationCredentialDocument,
    options
  );
}
export type SaveLinkLocationCredentialMutationHookResult = ReturnType<typeof useSaveLinkLocationCredentialMutation>;
export type SaveLinkLocationCredentialMutationResult =
  ApolloReactCommon.MutationResult<SaveLinkLocationCredentialMutation>;
export type SaveLinkLocationCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveLinkLocationCredentialMutation,
  SaveLinkLocationCredentialMutationVariables
>;
export const UpdateLinkLocationCategoriesDocument = gql`
  mutation UpdateLinkLocationCategories($input: UpdateLinkLocationCategoriesInput!) {
    updateLinkLocationCategories(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdateLinkLocationCategoriesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLinkLocationCategoriesMutation,
  UpdateLinkLocationCategoriesMutationVariables
>;

/**
 * __useUpdateLinkLocationCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateLinkLocationCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkLocationCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkLocationCategoriesMutation, { data, loading, error }] = useUpdateLinkLocationCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkLocationCategoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLinkLocationCategoriesMutation,
    UpdateLinkLocationCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateLinkLocationCategoriesMutation,
    UpdateLinkLocationCategoriesMutationVariables
  >(UpdateLinkLocationCategoriesDocument, options);
}
export type UpdateLinkLocationCategoriesMutationHookResult = ReturnType<typeof useUpdateLinkLocationCategoriesMutation>;
export type UpdateLinkLocationCategoriesMutationResult =
  ApolloReactCommon.MutationResult<UpdateLinkLocationCategoriesMutation>;
export type UpdateLinkLocationCategoriesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLinkLocationCategoriesMutation,
  UpdateLinkLocationCategoriesMutationVariables
>;
export const CreatePublisherUserDocument = gql`
  mutation CreatePublisherUser($input: CreatePublisherUserInput!) {
    createPublisherUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreatePublisherUserMutationFn = ApolloReactCommon.MutationFunction<
  CreatePublisherUserMutation,
  CreatePublisherUserMutationVariables
>;

/**
 * __useCreatePublisherUserMutation__
 *
 * To run a mutation, you first call `useCreatePublisherUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherUserMutation, { data, loading, error }] = useCreatePublisherUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublisherUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublisherUserMutation, CreatePublisherUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePublisherUserMutation, CreatePublisherUserMutationVariables>(
    CreatePublisherUserDocument,
    options
  );
}
export type CreatePublisherUserMutationHookResult = ReturnType<typeof useCreatePublisherUserMutation>;
export type CreatePublisherUserMutationResult = ApolloReactCommon.MutationResult<CreatePublisherUserMutation>;
export type CreatePublisherUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePublisherUserMutation,
  CreatePublisherUserMutationVariables
>;
export const CategoriesForSelectorDocument = gql`
  query CategoriesForSelector($search: String, $limit: Int, $offset: Int, $categoryIds: [Int!]) {
    categories(search: $search, limit: $limit, offset: $offset, categoryIds: $categoryIds) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCategoriesForSelectorQuery__
 *
 * To run a query within a React component, call `useCategoriesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useCategoriesForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export function useCategoriesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export type CategoriesForSelectorQueryHookResult = ReturnType<typeof useCategoriesForSelectorQuery>;
export type CategoriesForSelectorLazyQueryHookResult = ReturnType<typeof useCategoriesForSelectorLazyQuery>;
export type CategoriesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  CategoriesForSelectorQuery,
  CategoriesForSelectorQueryVariables
>;
export const LinkLocationStatusesDocument = gql`
  query LinkLocationStatuses {
    linkLocationStatuses {
      id
      name
    }
  }
`;

/**
 * __useLinkLocationStatusesQuery__
 *
 * To run a query within a React component, call `useLinkLocationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkLocationStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>(
    LinkLocationStatusesDocument,
    options
  );
}
export function useLinkLocationStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>(
    LinkLocationStatusesDocument,
    options
  );
}
export type LinkLocationStatusesQueryHookResult = ReturnType<typeof useLinkLocationStatusesQuery>;
export type LinkLocationStatusesLazyQueryHookResult = ReturnType<typeof useLinkLocationStatusesLazyQuery>;
export type LinkLocationStatusesQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationStatusesQuery,
  LinkLocationStatusesQueryVariables
>;
export const AuthManagerDocument = gql`
  query AuthManager {
    viewer {
      ...AuthManager_Viewer
    }
  }
  ${AuthManager_ViewerFragmentDoc}
`;

/**
 * __useAuthManagerQuery__
 *
 * To run a query within a React component, call `useAuthManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthManagerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export function useAuthManagerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export type AuthManagerQueryHookResult = ReturnType<typeof useAuthManagerQuery>;
export type AuthManagerLazyQueryHookResult = ReturnType<typeof useAuthManagerLazyQuery>;
export type AuthManagerQueryResult = ApolloReactCommon.QueryResult<AuthManagerQuery, AuthManagerQueryVariables>;
export const AuthPublisherDocument = gql`
  query AuthPublisher($id: Int!) {
    publisher(id: $id) {
      ...AuthManager_Publisher
    }
  }
  ${AuthManager_PublisherFragmentDoc}
`;

/**
 * __useAuthPublisherQuery__
 *
 * To run a query within a React component, call `useAuthPublisherQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthPublisherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthPublisherQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuthPublisherQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AuthPublisherQuery, AuthPublisherQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AuthPublisherQuery, AuthPublisherQueryVariables>(AuthPublisherDocument, options);
}
export function useAuthPublisherLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthPublisherQuery, AuthPublisherQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AuthPublisherQuery, AuthPublisherQueryVariables>(AuthPublisherDocument, options);
}
export type AuthPublisherQueryHookResult = ReturnType<typeof useAuthPublisherQuery>;
export type AuthPublisherLazyQueryHookResult = ReturnType<typeof useAuthPublisherLazyQuery>;
export type AuthPublisherQueryResult = ApolloReactCommon.QueryResult<AuthPublisherQuery, AuthPublisherQueryVariables>;
export const LogoutUserDocument = gql`
  mutation LogoutUser {
    logoutUser {
      isLoggedOut
    }
  }
`;
export type LogoutUserMutationFn = ApolloReactCommon.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
}
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = ApolloReactCommon.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;
export const PublisherAccountDocument = gql`
  query PublisherAccount($id: Int!) {
    publisher(id: $id) {
      id
      name
      contactEmail
      paymentMethod
      paymentMethodId
    }
  }
`;

/**
 * __usePublisherAccountQuery__
 *
 * To run a query within a React component, call `usePublisherAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublisherAccountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PublisherAccountQuery, PublisherAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublisherAccountQuery, PublisherAccountQueryVariables>(
    PublisherAccountDocument,
    options
  );
}
export function usePublisherAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublisherAccountQuery, PublisherAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublisherAccountQuery, PublisherAccountQueryVariables>(
    PublisherAccountDocument,
    options
  );
}
export type PublisherAccountQueryHookResult = ReturnType<typeof usePublisherAccountQuery>;
export type PublisherAccountLazyQueryHookResult = ReturnType<typeof usePublisherAccountLazyQuery>;
export type PublisherAccountQueryResult = ApolloReactCommon.QueryResult<
  PublisherAccountQuery,
  PublisherAccountQueryVariables
>;
export const PublisherAccount_UpdatePublisherDocument = gql`
  mutation PublisherAccount_UpdatePublisher($input: UpdatePublisherInput!) {
    updatePublisher(input: $input) {
      publisher {
        id
        name
        contactEmail
        paymentMethod
        paymentMethodId
        instructions
      }
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type PublisherAccount_UpdatePublisherMutationFn = ApolloReactCommon.MutationFunction<
  PublisherAccount_UpdatePublisherMutation,
  PublisherAccount_UpdatePublisherMutationVariables
>;

/**
 * __usePublisherAccount_UpdatePublisherMutation__
 *
 * To run a mutation, you first call `usePublisherAccount_UpdatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublisherAccount_UpdatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publisherAccountUpdatePublisherMutation, { data, loading, error }] = usePublisherAccount_UpdatePublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublisherAccount_UpdatePublisherMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublisherAccount_UpdatePublisherMutation,
    PublisherAccount_UpdatePublisherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PublisherAccount_UpdatePublisherMutation,
    PublisherAccount_UpdatePublisherMutationVariables
  >(PublisherAccount_UpdatePublisherDocument, options);
}
export type PublisherAccount_UpdatePublisherMutationHookResult = ReturnType<
  typeof usePublisherAccount_UpdatePublisherMutation
>;
export type PublisherAccount_UpdatePublisherMutationResult =
  ApolloReactCommon.MutationResult<PublisherAccount_UpdatePublisherMutation>;
export type PublisherAccount_UpdatePublisherMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PublisherAccount_UpdatePublisherMutation,
  PublisherAccount_UpdatePublisherMutationVariables
>;
export const LoginUserDocument = gql`
  mutation loginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      isLoggedIn
    }
  }
`;
export type LoginUserMutationFn = ApolloReactCommon.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = ApolloReactCommon.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserProfileDocument = gql`
  query UserProfile($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      emailPreference
    }
  }
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export function useUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = ApolloReactCommon.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const AdminPublisherMetricDocument = gql`
  query AdminPublisherMetric($publisherId: Int!) {
    publisher(id: $publisherId) {
      id
      publishedArticles7days: publishedOrders(daysAgo: 7) {
        totalCount
      }
      publishedArticles30days: publishedOrders(daysAgo: 30) {
        totalCount
      }
      publishedArticlesTotal: publishedOrders {
        totalCount
      }
      paidAmount30days: paidAmount(daysAgo: 30)
      paidAmountTotal: paidAmount
    }
  }
`;

/**
 * __useAdminPublisherMetricQuery__
 *
 * To run a query within a React component, call `useAdminPublisherMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublisherMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublisherMetricQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useAdminPublisherMetricQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>(
    AdminPublisherMetricDocument,
    options
  );
}
export function useAdminPublisherMetricLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>(
    AdminPublisherMetricDocument,
    options
  );
}
export type AdminPublisherMetricQueryHookResult = ReturnType<typeof useAdminPublisherMetricQuery>;
export type AdminPublisherMetricLazyQueryHookResult = ReturnType<typeof useAdminPublisherMetricLazyQuery>;
export type AdminPublisherMetricQueryResult = ApolloReactCommon.QueryResult<
  AdminPublisherMetricQuery,
  AdminPublisherMetricQueryVariables
>;
export const AdminPublishedOrdersDocument = gql`
  query AdminPublishedOrders(
    $publisherId: Int!
    $requestLimit: Int
    $requestOffset: Int
    $requestSort: OrdersSort
    $limit: Int
    $offset: Int
    $sort: OrdersSort
  ) {
    publisher(id: $publisherId) {
      requestedOrders(limit: $requestLimit, offset: $requestOffset, sort: $requestSort) {
        totalCount
        nodes {
          ...RequestedOrder
        }
      }
      publishedOrders(limit: $limit, offset: $offset, sort: $sort) {
        totalCount
        nodes {
          ...PublishedOrder
        }
      }
    }
  }
  ${RequestedOrderFragmentDoc}
  ${PublishedOrderFragmentDoc}
`;

/**
 * __useAdminPublishedOrdersQuery__
 *
 * To run a query within a React component, call `useAdminPublishedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublishedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublishedOrdersQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      requestLimit: // value for 'requestLimit'
 *      requestOffset: // value for 'requestOffset'
 *      requestSort: // value for 'requestSort'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminPublishedOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>(
    AdminPublishedOrdersDocument,
    options
  );
}
export function useAdminPublishedOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>(
    AdminPublishedOrdersDocument,
    options
  );
}
export type AdminPublishedOrdersQueryHookResult = ReturnType<typeof useAdminPublishedOrdersQuery>;
export type AdminPublishedOrdersLazyQueryHookResult = ReturnType<typeof useAdminPublishedOrdersLazyQuery>;
export type AdminPublishedOrdersQueryResult = ApolloReactCommon.QueryResult<
  AdminPublishedOrdersQuery,
  AdminPublishedOrdersQueryVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      wasRequested
    }
  }
`;
export type RequestPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      wasReset
    }
  }
`;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const CreatePublisherDocument = gql`
  mutation CreatePublisher($input: CreatePublisherInput!) {
    createPublisher(input: $input) {
      ok
      publisher {
        id
      }
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreatePublisherMutationFn = ApolloReactCommon.MutationFunction<
  CreatePublisherMutation,
  CreatePublisherMutationVariables
>;

/**
 * __useCreatePublisherMutation__
 *
 * To run a mutation, you first call `useCreatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherMutation, { data, loading, error }] = useCreatePublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublisherMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublisherMutation, CreatePublisherMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePublisherMutation, CreatePublisherMutationVariables>(
    CreatePublisherDocument,
    options
  );
}
export type CreatePublisherMutationHookResult = ReturnType<typeof useCreatePublisherMutation>;
export type CreatePublisherMutationResult = ApolloReactCommon.MutationResult<CreatePublisherMutation>;
export type CreatePublisherMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePublisherMutation,
  CreatePublisherMutationVariables
>;
export const UpdateLinkLocationDetailsDocument = gql`
  mutation UpdateLinkLocationDetails(
    $input: UpdateLinkLocationInput!
    $categoryInput: UpdateLinkLocationCategoriesInput!
  ) {
    updateLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
    updateLinkLocationCategories(input: $categoryInput) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdateLinkLocationDetailsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLinkLocationDetailsMutation,
  UpdateLinkLocationDetailsMutationVariables
>;

/**
 * __useUpdateLinkLocationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateLinkLocationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkLocationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkLocationDetailsMutation, { data, loading, error }] = useUpdateLinkLocationDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      categoryInput: // value for 'categoryInput'
 *   },
 * });
 */
export function useUpdateLinkLocationDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLinkLocationDetailsMutation,
    UpdateLinkLocationDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLinkLocationDetailsMutation, UpdateLinkLocationDetailsMutationVariables>(
    UpdateLinkLocationDetailsDocument,
    options
  );
}
export type UpdateLinkLocationDetailsMutationHookResult = ReturnType<typeof useUpdateLinkLocationDetailsMutation>;
export type UpdateLinkLocationDetailsMutationResult =
  ApolloReactCommon.MutationResult<UpdateLinkLocationDetailsMutation>;
export type UpdateLinkLocationDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLinkLocationDetailsMutation,
  UpdateLinkLocationDetailsMutationVariables
>;
export const UpdatePublisherLinkLocationDocument = gql`
  mutation UpdatePublisherLinkLocation($input: UpdatePublisherLinkLocationInput!) {
    updatePublisherLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdatePublisherLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublisherLinkLocationMutation,
  UpdatePublisherLinkLocationMutationVariables
>;

/**
 * __useUpdatePublisherLinkLocationMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherLinkLocationMutation, { data, loading, error }] = useUpdatePublisherLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePublisherLinkLocationMutation,
    UpdatePublisherLinkLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePublisherLinkLocationMutation,
    UpdatePublisherLinkLocationMutationVariables
  >(UpdatePublisherLinkLocationDocument, options);
}
export type UpdatePublisherLinkLocationMutationHookResult = ReturnType<typeof useUpdatePublisherLinkLocationMutation>;
export type UpdatePublisherLinkLocationMutationResult =
  ApolloReactCommon.MutationResult<UpdatePublisherLinkLocationMutation>;
export type UpdatePublisherLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublisherLinkLocationMutation,
  UpdatePublisherLinkLocationMutationVariables
>;
export const SiteDetailsSaveLinkLocationCredentialDocument = gql`
  mutation SiteDetailsSaveLinkLocationCredential($input: SaveLinkLocationCredentialInput!) {
    saveLinkLocationCredential(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SiteDetailsSaveLinkLocationCredentialMutationFn = ApolloReactCommon.MutationFunction<
  SiteDetailsSaveLinkLocationCredentialMutation,
  SiteDetailsSaveLinkLocationCredentialMutationVariables
>;

/**
 * __useSiteDetailsSaveLinkLocationCredentialMutation__
 *
 * To run a mutation, you first call `useSiteDetailsSaveLinkLocationCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteDetailsSaveLinkLocationCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteDetailsSaveLinkLocationCredentialMutation, { data, loading, error }] = useSiteDetailsSaveLinkLocationCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteDetailsSaveLinkLocationCredentialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SiteDetailsSaveLinkLocationCredentialMutation,
    SiteDetailsSaveLinkLocationCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SiteDetailsSaveLinkLocationCredentialMutation,
    SiteDetailsSaveLinkLocationCredentialMutationVariables
  >(SiteDetailsSaveLinkLocationCredentialDocument, options);
}
export type SiteDetailsSaveLinkLocationCredentialMutationHookResult = ReturnType<
  typeof useSiteDetailsSaveLinkLocationCredentialMutation
>;
export type SiteDetailsSaveLinkLocationCredentialMutationResult =
  ApolloReactCommon.MutationResult<SiteDetailsSaveLinkLocationCredentialMutation>;
export type SiteDetailsSaveLinkLocationCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SiteDetailsSaveLinkLocationCredentialMutation,
  SiteDetailsSaveLinkLocationCredentialMutationVariables
>;
export const LinkLocationDetailsDocument = gql`
  query LinkLocationDetails($id: Int!) {
    linkLocation(id: $id) {
      id
      domain
      domainAuthority
      spam
      authorityScore
      monthlyTraffic
      notes
      minimumWordCount
      cost
      contactName
      contactEmail
      contactPhone
      statusId
      linkLocationCategories {
        id
        category {
          id
          name
        }
      }
      publisherLinkLocation {
        id
        isRCMPublishing
      }
      linkLocationCredential {
        id
        adminUrl
        userName
        password
        instructions
      }
    }
  }
`;

/**
 * __useLinkLocationDetailsQuery__
 *
 * To run a query within a React component, call `useLinkLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkLocationDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>(
    LinkLocationDetailsDocument,
    options
  );
}
export function useLinkLocationDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>(
    LinkLocationDetailsDocument,
    options
  );
}
export type LinkLocationDetailsQueryHookResult = ReturnType<typeof useLinkLocationDetailsQuery>;
export type LinkLocationDetailsLazyQueryHookResult = ReturnType<typeof useLinkLocationDetailsLazyQuery>;
export type LinkLocationDetailsQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationDetailsQuery,
  LinkLocationDetailsQueryVariables
>;
export const SitesDocument = gql`
  query Sites(
    $publisherId: Int!
    $limit: Int
    $offset: Int
    $statusIds: [String!]
    $search: String
    $sort: PublisherLinkLocationSort
  ) {
    publisherLinkLocations(
      publisherId: $publisherId
      limit: $limit
      offset: $offset
      statusIds: $statusIds
      search: $search
      sort: $sort
    ) {
      totalCount
      nodes {
        id
        linkLocation {
          id
          domain
          domainAuthority
          authorityScore
          monthlyTraffic
          cost
          status {
            id
            name
          }
          linkLocationCategories {
            id
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      statusIds: // value for 'statusIds'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSitesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
}
export function useSitesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
}
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = ApolloReactCommon.QueryResult<SitesQuery, SitesQueryVariables>;
export const Sso_LoginUserDocument = gql`
  mutation SSO_LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      isLoggedIn
    }
  }
`;
export type Sso_LoginUserMutationFn = ApolloReactCommon.MutationFunction<
  Sso_LoginUserMutation,
  Sso_LoginUserMutationVariables
>;

/**
 * __useSso_LoginUserMutation__
 *
 * To run a mutation, you first call `useSso_LoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSso_LoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoLoginUserMutation, { data, loading, error }] = useSso_LoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSso_LoginUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Sso_LoginUserMutation, Sso_LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<Sso_LoginUserMutation, Sso_LoginUserMutationVariables>(
    Sso_LoginUserDocument,
    options
  );
}
export type Sso_LoginUserMutationHookResult = ReturnType<typeof useSso_LoginUserMutation>;
export type Sso_LoginUserMutationResult = ApolloReactCommon.MutationResult<Sso_LoginUserMutation>;
export type Sso_LoginUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Sso_LoginUserMutation,
  Sso_LoginUserMutationVariables
>;
export const TaskWorkspaceDocument = gql`
  query TaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      taskType {
        id
        name
      }
    }
  }
`;

/**
 * __useTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>(TaskWorkspaceDocument, options);
}
export function useTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>(TaskWorkspaceDocument, options);
}
export type TaskWorkspaceQueryHookResult = ReturnType<typeof useTaskWorkspaceQuery>;
export type TaskWorkspaceLazyQueryHookResult = ReturnType<typeof useTaskWorkspaceLazyQuery>;
export type TaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>;
export const SubmitProposalReviewDocument = gql`
  mutation SubmitProposalReview($input: SubmitProposalReviewInput!) {
    submitProposalReview(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SubmitProposalReviewMutationFn = ApolloReactCommon.MutationFunction<
  SubmitProposalReviewMutation,
  SubmitProposalReviewMutationVariables
>;

/**
 * __useSubmitProposalReviewMutation__
 *
 * To run a mutation, you first call `useSubmitProposalReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProposalReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitProposalReviewMutation, { data, loading, error }] = useSubmitProposalReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitProposalReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitProposalReviewMutation,
    SubmitProposalReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SubmitProposalReviewMutation, SubmitProposalReviewMutationVariables>(
    SubmitProposalReviewDocument,
    options
  );
}
export type SubmitProposalReviewMutationHookResult = ReturnType<typeof useSubmitProposalReviewMutation>;
export type SubmitProposalReviewMutationResult = ApolloReactCommon.MutationResult<SubmitProposalReviewMutation>;
export type SubmitProposalReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitProposalReviewMutation,
  SubmitProposalReviewMutationVariables
>;
export const UpdateOrderDocument = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export type UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
}
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = ApolloReactCommon.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;
export const ProposalReviewTaskWorkspaceDocument = gql`
  query ProposalReviewTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      status {
        id
        name
      }
      order {
        id
        targetResultUrl
        targetLinkLocation {
          id
          cost
        }
      }
      reviewTargetTask {
        id
        contentItem {
          id
          title
          content
        }
      }
    }
  }
`;

/**
 * __useProposalReviewTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useProposalReviewTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalReviewTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalReviewTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useProposalReviewTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProposalReviewTaskWorkspaceQuery,
    ProposalReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProposalReviewTaskWorkspaceQuery, ProposalReviewTaskWorkspaceQueryVariables>(
    ProposalReviewTaskWorkspaceDocument,
    options
  );
}
export function useProposalReviewTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProposalReviewTaskWorkspaceQuery,
    ProposalReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProposalReviewTaskWorkspaceQuery, ProposalReviewTaskWorkspaceQueryVariables>(
    ProposalReviewTaskWorkspaceDocument,
    options
  );
}
export type ProposalReviewTaskWorkspaceQueryHookResult = ReturnType<typeof useProposalReviewTaskWorkspaceQuery>;
export type ProposalReviewTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useProposalReviewTaskWorkspaceLazyQuery>;
export type ProposalReviewTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ProposalReviewTaskWorkspaceQuery,
  ProposalReviewTaskWorkspaceQueryVariables
>;
export const PublisherUsersDocument = gql`
  query PublisherUsers($id: Int!, $limit: Int!, $offset: Int!, $search: String, $includeInactive: Boolean) {
    publisher(id: $id) {
      id
      publisherUsers(limit: $limit, offset: $offset, search: $search, includeInactive: $includeInactive) {
        totalCount
        nodes {
          user {
            id
            fullName
            email
          }
        }
      }
    }
  }
`;

/**
 * __usePublisherUsersQuery__
 *
 * To run a query within a React component, call `usePublisherUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function usePublisherUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PublisherUsersQuery, PublisherUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublisherUsersQuery, PublisherUsersQueryVariables>(PublisherUsersDocument, options);
}
export function usePublisherUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublisherUsersQuery, PublisherUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublisherUsersQuery, PublisherUsersQueryVariables>(
    PublisherUsersDocument,
    options
  );
}
export type PublisherUsersQueryHookResult = ReturnType<typeof usePublisherUsersQuery>;
export type PublisherUsersLazyQueryHookResult = ReturnType<typeof usePublisherUsersLazyQuery>;
export type PublisherUsersQueryResult = ApolloReactCommon.QueryResult<
  PublisherUsersQuery,
  PublisherUsersQueryVariables
>;
